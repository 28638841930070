import '../css/statusbodystatustable.css';
import React, { useState, useEffect } from 'react';
import Spinner from './spinner'
import Accordion from './statusbodyaccordion'
import DealerStatusAccordion from './dealerstatusaccordion'

function StatusBodyStatusTable(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

  	const [statuses, setStatuses] = useState([])

  	const [showLoader, setShowLoader] = useState(true)

  	useEffect(() => {
    	fetchData();
  	}, [BASE_URL]);

  	useEffect(() => {
    var apikey = localStorage.getItem('drm_session_apikey')
    if ( props.shouldRefresh) {
      	setShowLoader(true)
      	props.handleShowSpinner(true)
    	fetch( BASE_URL + '/v1/status/active', {
      		method : 'GET',
      		headers : {
        		'x-api-key' : apikey
      		}
    	})
    	.then(res => res.json())
    	.then((data) => {
        	setStatuses(data['message'])
        	props.handleShowSpinner(false)
        	setShowLoader(false)
    	})
    	.catch(console.log)
    }
  }, [props.shouldRefresh])

  	function fetchData() {
  		setShowLoader(true)
  		var apikey = localStorage.getItem('drm_session_apikey')
    	fetch( BASE_URL + '/v1/status/active', {
      		method : 'GET',
      		headers : {
        		'x-api-key' : apikey
      		}
    	})
    	.then(res => res.json())
    	.then((data) => {
        	setStatuses(data['message'])
        	setShowLoader(false)
    	})
    	.catch(console.log)
  	}

	return (
		<div className="App-body-right-tab-status-body-contents">
			{showLoader ? 
				<Spinner />
				:
				statuses.flatMap((row, index) => (
					row.status != "Archiving Data" ?
					<DealerStatusAccordion refreshData={fetchData} key={index} dealer_status={row} content={row.dealer_id} />
					: <div></div>
					
				))
			}
        </div>
	)
}

export default StatusBodyStatusTable;
