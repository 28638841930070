import '../css/agentsonline.css';
import userOnlineLogo from '../useronline.svg';



function AgentsOnline(props) {

  return (
    <div className="App-body-right-tab-dashboard-overview-agent-online">
      <div className="App-body-right-tab-dashboard-overview-agent-online-icon">
        <div className="App-body-right-tab-dashboard-overview-agent-online-icon-container">
          <img src={userOnlineLogo} alt="agent-online" className="App-body-right-tab-dashboard-overview-agent-online-icon-logo"/>
        </div>
      </div>
      <div className="App-body-right-tab-dashboard-overview-agent-online-content">
        <div className="App-body-right-tab-dashboard-overview-agent-online-content-title">
          Agents Online
        </div>
        <div className="App-body-right-tab-dashboard-overview-agent-online-content-count">
          {props.agent_online}
        </div>
      </div>
    </div>
  );
}

export default AgentsOnline;
