import '../App.css';
import storeRiskLogo from '../alert2.svg';


function RiskStores(props) {

  return (
    <div className="App-body-right-tab-dashboard-overview-risk-store">
      <div className="App-body-right-tab-dashboard-overview-risk-icon">
        <div className="App-body-right-tab-dashboard-overview-risk-icon-container">
          <img src={storeRiskLogo} alt="stores-risk" className="App-body-right-tab-dashboard-overview-risk-icon-logo"/>
        </div>
      </div>
      <div className="App-body-right-tab-dashboard-overview-risk-content">
        <div className="App-body-right-tab-dashboard-overview-risk-content-title">
          Stores Migrated
        </div>
        <div className="App-body-right-tab-dashboard-overview-risk-content-count">
          {props.stores_completed}
        </div>
      </div>
    </div>
  );
}

export default RiskStores;
