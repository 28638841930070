import '../css/status.css';

function StatusTitle(props) {

  return (
    <div className="App-body-right-tab-status-title">
      <div className="App-body-right-tab-status-title-container">
        <div className="App-body-right-tab-status-title-name">
          Document Extraction Status
        </div>
        <div className="App-body-right-tab-dashboard-refresh">
            {props.showSpinner ?
                <i class="fa fa-spinner" id="App-body-right-tab-dashboard-spinner-icon" aria-hidden="true"></i> 
              :
                <i class="fa fa-refresh" id="App-body-right-tab-dashboard-refresh-icon" aria-hidden="true" onClick={props.handleDashboardRefresh}></i>
            }
        </div>
      </div>
    </div>
  );
}

export default StatusTitle;
