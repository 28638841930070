import React, { useState, useEffect } from 'react';
import ProgressIcon from './yearprogressicon'

function MetaProgressIcon(props) {

  const [iconStatus, setIconStatus] = useState("")

  useEffect(() => {
    if (props.currentState == "metadata_start") {
      setIconStatus("Start")
    } else if ( props.currentState == "metadata_run") {
      setIconStatus("Running")
    } else if ( props.currentState == "metadata_fail") {
      setIconStatus("Failed")
    } else {
      setIconStatus("Completed")
    }
  }, [props.currentState])

  function handleOnClick() {
    console.log("Icon clicked")
    props.onClick(props.job_data)
  }

  return(
      <ProgressIcon currentState={iconStatus} onClick={handleOnClick}/>
    );
}

export default MetaProgressIcon