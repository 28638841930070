import '../css/dashboardbodycontents.css';
import DashboardBodyContentTable from './dashboardbodycontenttable'

function DashboardBodyContents(props) {

  return (
      <div className="App-body-right-tab-dashboard-content-container">
        <div className="App-body-right-tab-dashboard-content-title-container">
            <div className="App-body-right-tab-dashboard-content-title">
                Extractions In Progress
            </div>
        </div>
        <div className="App-body-right-tab-dashboard-content-contents">
            <DashboardBodyContentTable  updateRefreshedTime={props.updateRefreshedTime} shouldRefresh={props.shouldRefresh} handleShowSpinner={props.handleShowSpinner} setOverview={props.setOverview} />
        </div>
      </div>
  );
}

export default DashboardBodyContents;
