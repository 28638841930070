import React, { useState, useEffect} from 'react';
import '../css/errormodal.css'


function ErrorModal(props) {

	return(
			<div id="myModal" class="App-body-error-modal">
				<div class="App-body-error-modal-content">
					<div class="App-body-error-modal-header">
						<div class="App-body-error-modal-header-content">Error</div>
		     			<div class="close" onClick={props.handleErrorClose}>&times;</div>
					</div>
					<div class="App-body-error-modal-body">
						<div class="App-body-error-modal-body-label">
						{props.message}
						</div>
					</div>
				</div>
			</div>
		)

}

export default ErrorModal