import '../css/statusbodyyearprogresstable.css';
import React, { useState, useEffect } from 'react';
import StatusBodyYearProgress from './statusbodyyearprogress'

function StatusBodyYearProgressTable(props) {

	return (
		<div className="App-body-right-tab-status-year-progress-contents">
			{Object.keys(props.extractionData).map((year, index) => (
              <StatusBodyYearProgress 
              	dealer_data={props.dealer_status} 
              	key={index} 
              	showError={props.showError}
              	year={year}
              	subModule={props.subModule}
          		module={props.module} 
              	handleRefresh={props.handleRefresh} 
              	year_data={props.extractionData[year]}
              />
            ))}

        </div>
	)
}

export default StatusBodyYearProgressTable;
