import '../App.css';
import Icon from './icon'

function LeftTab(props) {

  return (
    <div className="App-body-block-left-tab-container">
      <div className="App-body-block-left-tab-icon-container">
        <Icon name="Dashboard" handleClick={props.handleTabSelection}/>
        <Icon name="Agent" handleClick={props.handleTabSelection}/>
        <Icon name="Status" handleClick={props.handleTabSelection}/>
      </div>
      <div className="App-body-block-left-tab-avatar-container">
      </div>
    </div>
  );
}

export default LeftTab;
