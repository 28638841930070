import '../css/statusbodycontainer.css';
import settingsLogo from '../settings.svg';


function StatusBodyTitle() {

  return (
      <div className="App-body-right-tab-status-body-title">
        <div className="App-body-right-tab-status-body-title-content" id="App-body-right-tab-status-body-title-collapse-icon">
        </div>
        <div className="App-body-right-tab-status-body-title-content" id="App-body-right-tab-status-body-title-group-name">
          <div className="App-body-right-tab-status-body-title-content-detail">
            Group Name
          </div>
        </div>
        <div className="App-body-right-tab-status-body-title-content" id="App-body-right-tab-status-body-title-dealership-name">
          <div className="App-body-right-tab-status-body-title-content-detail">
            Dealership Name
          </div>
        </div>
        <div className="App-body-right-tab-status-body-title-content" id="App-body-right-tab-status-body-title-dealer-id">
          <div className="App-body-right-tab-status-body-title-content-detail">
            Dealer ID
          </div>
        </div>
        <div className="App-body-right-tab-status-body-title-content" id="App-body-right-tab-status-body-title-dms-type">
          <div className="App-body-right-tab-status-body-title-content-detail">
            DMS Type
          </div>
        </div>
        <div className="App-body-right-tab-status-body-title-content" id="App-body-right-tab-status-body-title-go-live-date">
          <div className="App-body-right-tab-status-body-title-content-detail">
            Go Live Date
          </div>
        </div>
        <div className="App-body-right-tab-status-body-title-content" id="App-body-right-tab-status-body-title-last-updated">
          <div className="App-body-right-tab-status-body-title-content-detail">
            Last Updated
          </div>
        </div>
        <div className="App-body-right-tab-status-body-title-content" id="App-body-right-tab-status-body-title-status">
          <div className="App-body-right-tab-status-body-title-content-detail">
            Status
          </div>
        </div>
        <div className="App-body-right-tab-status-body-title-content" id="App-body-right-tab-status-body-title-add-info">
          <div className="App-body-right-tab-status-body-title-content-detail">
            <img src={settingsLogo} alt="additional-info"/>
          </div>
        </div>
      </div>
  );
}

export default StatusBodyTitle;