import '../App.css';
import DashboardTitle from './dashboardtitle'
import DashboardBody from './dashboardbody'
import React, { useState, useEffect } from 'react';

function Dashboard() {

  const [showSpinner, setShowSpinner] = useState(false)

  const [shouldRefresh, setShouldRefresh] = useState(false)

  const [lastRefreshTime, setLastRefreshTime] = useState('')


  function setShowRefreshSpinner(showSpinner) {
    setShowSpinner(showSpinner)
    setShouldRefresh(false)
  }

  function handleDashboardRefresh() {
    setShouldRefresh(true)
    setShowSpinner(true)
  }

  function updateRefreshedTime(lastRefreshTime) {
      setLastRefreshTime(lastRefreshTime)
  }

  return (
    <div className="App-body-right-tab-dashboard-container">
      <DashboardTitle lastRefreshTime={lastRefreshTime} showSpinner={showSpinner} handleDashboardRefresh={handleDashboardRefresh}/>
      <DashboardBody  updateRefreshedTime={updateRefreshedTime} shouldRefresh={shouldRefresh} handleShowSpinner={setShowRefreshSpinner} />
    </div>
  );
}

export default Dashboard;
