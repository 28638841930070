import React, { useState, useEffect} from 'react';
import '../css/documentinsightstatus.css'
import YearProgressBar from './progressBar'


function DocumentInsightStatus(props) {

	return(
			<div class="App-body-status-year-job-insights-modal-body-document-status">
				<div class="App-body-status-year-job-insights-modal-body-document-status-title">
							Document Status
						</div>
						<div class="App-body-status-year-job-insights-modal-body-document-status-table">
							<div class="App-body-status-year-job-insights-modal-body-document-status-table-title">
								<div className="App-body-status-year-job-insights-modal-body-document-status-title-content" id="App-body-status-year-job-insights-modal-body-document-status-document-module">
          							<div className="App-body-status-year-job-insights-modal-body-document-status-title-content-detail">
          								Module
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-document-status-title-content" id="App-body-status-year-job-insights-modal-body-document-status-document-estimate">
          							<div className="App-body-status-year-job-insights-modal-body-document-status-title-content-detail">
          								Estimated Doc Count
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-document-status-title-content" id="App-body-status-year-job-insights-modal-body-document-status-document-extract">
          							<div className="App-body-status-year-job-insights-modal-body-document-status-title-content-detail">
          								Extracted Doc Count
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-document-status-title-content" id="App-body-status-year-job-insights-modal-body-document-status-document-progress">
          							<div className="App-body-status-year-job-insights-modal-body-document-status-title-content-detail">
          								Progress
          							</div>
          						</div>
							</div>
							<div class="App-body-status-year-job-insights-modal-body-document-status-table-details">
								{ props.insights.module_data.map((module_value) => (

										<div class="App-body-status-year-job-insights-modal-body-document-status-table-details-row">
											<div className="App-body-status-year-job-insights-modal-body-document-status-detail-content" id="App-body-status-year-job-insights-modal-body-document-status-document-module-value">
			          							<div className="App-body-status-year-job-insights-modal-body-document-status-title-content-detail">
			          								{module_value.module}
			          							</div>
			          						</div>
			          						<div className="App-body-status-year-job-insights-modal-body-document-status-detail-content" id="App-body-status-year-job-insights-modal-body-document-status-document-estimate-value">
			          							<div className="App-body-status-year-job-insights-modal-body-document-status-title-content-detail">
			          								{module_value.estimated_count}
			          							</div>
			          						</div>
			          						<div className="App-body-status-year-job-insights-modal-body-document-status-detail-content" id="App-body-status-year-job-insights-modal-body-document-status-document-extract-value">
			          							<div className="App-body-status-year-job-insights-modal-body-document-status-title-content-detail">
			          								{module_value.actual_count}
			          							</div>
			          						</div>
			          						<div className="App-body-status-year-job-insights-modal-body-document-status-detail-content" id="App-body-status-year-job-insights-modal-body-document-status-document-progress-value">
			          							<div className="App-body-status-year-job-insights-modal-body-document-status-title-content-detail">
			          								<YearProgressBar progress={Math.round(module_value.actual_count/module_value.estimated_count * 100)} />
			          							</div>
			          						</div>
          								</div>
									))
								}
							</div>
						</div>
			</div>
		)

}

export default DocumentInsightStatus