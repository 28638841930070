import '../css/status.css';
import StatusBodyContents from './statusbodycontents'

function StatusBody(props) {

  return (
    <div className="App-body-right-tab-status-body">
      <StatusBodyContents shouldRefresh={props.shouldRefresh} handleShowSpinner={props.handleShowSpinner} />
    </div>
  );
}

export default StatusBody;
