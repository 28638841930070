import '../css/statusbodyyearprogresstable.css';
import React, { useState, useEffect } from 'react';
import MetaProgressIcon from './metaprogressicon'
import MetaYearProgressBar from './metayearprogressbar'
import DocumentYearProgressBar from './documentyearprogressbar'
import ValidationYearProgressBar from './validationyearprogressbar'
import YearProgressBar from './metayearprogressbar'
import DocumentProgressIcon from './documentprogressicon'
import ValidationProgressIcon from './validationprogressicon'
import MigrationProgressIcon from './migrationprogressicon'
import MigrationActionItem from './migrationactionitem'
import JobDetails from './jobdetails'
import ErrorModal from './errormodal'


function StatusBodyYearProgress(props) {

	const [currentState, setCurrentState] = useState('')
	const [selectedJob, setSelectedJob] = useState({})
	const [showJobDetail, setShowJobDetail] = useState(false)
	const [showLoader, setShowLoader] = useState(false)
	const [showError, setShowError] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')

	const BASE_URL = process.env.REACT_APP_BASE_URL;


	useEffect(() => {
		var meta_data = props.year_data.metadata
		var document_data = props.year_data.documents
		var validation_data = props.year_data.validation
	    if ( meta_data === undefined || Object.keys(meta_data).length === 0 ) {
	      setCurrentState("metadata_start")
	    } else {
			if ( meta_data.status === "Running" || meta_data.status === "Pending" ) {
			setCurrentState("metadata_run")
			} else if ( meta_data.status === "Completed" ) {
	        	setCurrentState("metadata_complete")
	        	if ( document_data === undefined || Object.keys(document_data).length === 0 ) {
	          		 setCurrentState("document_start")
	        	} else {
	          		if ( document_data.status === "Running" || document_data.status === "Pending" ) {
	            		setCurrentState("document_run")
	         		 } else if ( document_data.status === "Completed" ) {
	            		setCurrentState("document_complete")
			            if ( validation_data === undefined || Object.keys(validation_data).length === 0 ) {
			              setCurrentState("validation_start")
			            } else {
			                if ( validation_data.status == "Running" || validation_data.status == "Pending" ) {
			                  setCurrentState("validation_run")
			                } else if ( validation_data.status === "Completed" ) {
			                  setCurrentState("migration_start")
			                }
			                else if ( validation_data.status === "Failed") {
			                  setCurrentState("validation_fail")
			                  props.showError(true)
			                }
	            		}
          			} else if ( document_data.status === "Failed" || document_data.status === "Cancelled") {
	           	 		setCurrentState("document_fail")
	           	 		props.showError(true)
	          		}
	        	} 
	      } else if ( meta_data.status === "Failed" || meta_data.status === "Cancelled") {
	        setCurrentState("metadata_fail")
	        props.showError(true)
	      }
		}
	},  [props.year_data])


	function getJobType(currentState) {
		var type = ''
		switch(currentState) {
			case "metadata_start" :
				type = "metadata"
				break;
			case "metadata_run" :
				break;
			case "metadata_fail" :
				type = "metadata"
				break;
			case "document_start" :
				type = "documents"
				break;
			case "document_run" :
				break;
			case "document_fail" :
				type="documents"
				break;
			case "validation_start" :
				type="validation" 
				break ; 
		}
		return type;
	}

	function handleJobDetail(job_data) {
		if ( job_data != null) {
			if( job_data.status === "Completed" || job_data.status === "Failed" || job_data.status === "Running" || job_data.status === "Cancelled" || job_data.status === "Pending") {
				setShowJobDetail(true)
				setSelectedJob(job_data)
			}
		}
	}

	function handleJobClose() {
		setShowJobDetail(false)
		setSelectedJob({})
	}

	function handleErrorClose() {
		console.log("Close clicked")
		setShowError(false)
		setErrorMsg('')
	}

	function handleJobClick() {
		setShowLoader(true)
		var session_key = localStorage.getItem('drm_session_key')
    	var session_apikey = localStorage.getItem('drm_session_apikey')
		var job_type = getJobType(currentState)
		var years = [ props.year]
		if ( currentState == "document_fail" || currentState == "metadata_fail" || currentState == "validation_fail") {
			var job_id = ""
			if ( currentState == "document_fail") {
				job_id = props.year_data.documents.job_id
			} else if ( currentState == "validation_fail") {
				job_id = props.year_data.validation.job_id
			} else {
				job_id = props.year_data.metadata.job_id
			}
			fetch(BASE_URL+"/v1/jobs/" + job_id +'/retry', {
					method : 'POST',
				    headers:{
						'x-api-key': session_apikey,
						'user_key' : session_key
					}
			}).then(res => {
				if (res.status == "500") {
                	res.json().then((data) => {
                		console.log(data)
                 		setShowError(true)
                  		setErrorMsg(data['message']['error_message'])
                	})
            	} else {
              		return res.json()
            	}
			})
		    .then((data) => {
		    	setShowLoader(false)
      			if( data != undefined) {
        			props.handleRefresh(props.module, props.subModule)
        		}
		    }).catch(console.log)
		} else {
			fetch(BASE_URL+'/v2/jobs/trigger', {
        		method: 'POST',
		        headers: {
		          'Accept':'application/json',
		          'Content-Type':'application/json',
		          'x-api-key': session_apikey,
		          'user_key' : session_key
		        },
        		body : JSON.stringify({
		          tenant_name: props.dealer_data.tenant_name,
		          dealer_id: props.dealer_data.dealer_id,
		          module: props.module.toLowerCase(),
		          sub_module : props.subModule,
		          dms_type : props.dealer_data.dms_type,
		          type : job_type,
		          year : years,
		          is_fresh_extract : true
       		 	})  
      		}).then(res => {
      			if (res.status == "500") {
                	res.json().then((data) => {
                 		setShowError(true)
                  		setErrorMsg(data['message']['error_message'])
                	})
            	} else {
              		return res.json()
            	}
      		})
      		.then((data) => {
				setShowLoader(false)
      			if( data != undefined) {
    				props.handleRefresh(props.module, props.subModule)
    				localStorage.setItem('last_active_time', JSON.stringify(Date.now()))
    			}
      		})
		}
	}
 
	return (
		<div className="App-body-right-tab-status-year-progress-content">
		{showError ? <ErrorModal message={errorMsg} handleErrorClose={handleErrorClose} /> : <div></div>}
			<div className="App-body-right-tab-year-progress-body-content" id="App-body-right-tab-year-progress-body-year">
				{props.year}
			</div>
			<div className="App-body-right-tab-year-progress-body-content" id="App-body-right-tab-year-progress-body-extract-description">
				<MetaProgressIcon step="Metadata" currentState={currentState} year={props.year} job_data={props.year_data.metadata} onClick={handleJobDetail}/>
				<MetaYearProgressBar currentState={currentState} year={props.year} />
				<DocumentProgressIcon step="Documents" currentState={currentState} year={props.year} job_data={props.year_data.documents} onClick={handleJobDetail} />
				<DocumentYearProgressBar currentState={currentState} />
				<ValidationProgressIcon step="Validation" currentState={currentState} year={props.year} job_data={props.year_data.validation} onClick={handleJobDetail} />
				<ValidationYearProgressBar currentState={currentState} />
				<MigrationProgressIcon step="Migration" currentState={currentState} year={props.year} job_data={props.year_data.migration} onClick={handleJobDetail} />
			</div>
			<div className="App-body-right-tab-year-progress-body-content" id="App-body-right-tab-year-progress-body-actions">
				<MigrationActionItem showLoader={showLoader} currentState={currentState} handleJobClick={handleJobClick} />
			</div>
			{showJobDetail ? <JobDetails job={selectedJob} handleJobClose={handleJobClose} /> : <div></div>}
        </div>
	)
}

export default StatusBodyYearProgress;
