import '../css/jobsfail.css';
import crossLogo from '../cross.svg';


function JobsFail(props) {

  return (
    <div className="App-body-right-tab-dashboard-overview-job-fail">
      <div className="App-body-right-tab-dashboard-overview-job-fail-icon">
        <div className="App-body-right-tab-dashboard-overview-job-fail-icon-container">
          <img src={crossLogo} alt="job-fail" className="App-body-right-tab-dashboard-overview-job-fail-icon-logo"/>
        </div>
      </div>
      <div className="App-body-right-tab-dashboard-overview-job-fail-content">
        <div className="App-body-right-tab-dashboard-overview-job-fail-content-title">
          Jobs Fail
        </div>
        <div className="App-body-right-tab-dashboard-overview-job-fail-content-count">
          {props.job_fail}
        </div>
      </div>
    </div>
  );
}

export default JobsFail;
