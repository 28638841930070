import '../css/statusbodyyearprogresscontainer.css';

function StatusBodyYearProgressTitle() {

  return (
      <div className="App-body-right-tab-year-progress-body-title">
        <div className="App-body-right-tab-year-progress-body-title-content" id="App-body-right-tab-year-progress-body-title-year">
          <div className="App-body-right-tab-year-progress-body-title-content-detail">
            Year
          </div>
        </div>
        <div className="App-body-right-tab-year-progress-body-title-content" id="App-body-right-tab-year-progress-body-title-extract-description">
          <div className="App-body-right-tab-year-progress-body-title-content-detail">
            Extract Metadata
          </div>
          <div className="App-body-right-tab-year-progress-body-title-content-detail" id="App-body-right-tab-year-progress-body-title-detail-arrow">
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </div>
          <div className="App-body-right-tab-year-progress-body-title-content-detail">
            Extract Documents
          </div>
          <div className="App-body-right-tab-year-progress-body-title-content-detail" id="App-body-right-tab-year-progress-body-title-detail-arrow">
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </div>
          <div className="App-body-right-tab-year-progress-body-title-content-detail">
            Validate Documents
          </div>
          <div className="App-body-right-tab-year-progress-body-title-content-detail" id="App-body-right-tab-year-progress-body-title-detail-arrow">
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </div>
          <div className="App-body-right-tab-year-progress-body-title-content-detail">
            Migrate Documents
          </div>
        </div>
        <div className="App-body-right-tab-year-progress-body-title-content" id="App-body-right-tab-year-progress-body-title-actions">
          <div className="App-body-right-tab-year-progress-body-title-content-detail">
            Status / Actions
          </div>
        </div>
      </div>
  );
}

export default StatusBodyYearProgressTitle;