import '../App.css';
import storeRiskLogo from '../alert2.svg';
import React, { useState, useEffect } from 'react';


function DocumentCount(props) {

  var [valueInWords, setValueInWords] = useState('')

  useEffect(() => {
    setValueInWords(convertToLabelValue(props.document_count))
  }, [props.document_count])

  function convertToLabelValue (labelValue) {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + " B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + " M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + " K"

    : Math.abs(Number(labelValue));

}

  return (
    <div className="App-body-right-tab-dashboard-overview-risk-store">
      <div className="App-body-right-tab-dashboard-overview-risk-icon">
        <div className="App-body-right-tab-dashboard-overview-risk-icon-container">
          <img src={storeRiskLogo} alt="stores-risk" className="App-body-right-tab-dashboard-overview-risk-icon-logo"/>
        </div>
      </div>
      <div className="App-body-right-tab-dashboard-overview-risk-content">
        <div className="App-body-right-tab-dashboard-overview-risk-content-title">
          Extracted Docs Count
        </div>
        <div className="App-body-right-tab-dashboard-overview-risk-content-count">
          {valueInWords}
        </div>
      </div>
    </div>
  );
}

export default DocumentCount;
