import React, { useState, useEffect } from 'react';
import '../css/jobdetails.css'
import JobStatusLabel from './jobsstatuslabel'
import Logs from './logs'
import userProfile from '../userprofile.svg';
import ValidationResult from './validationresult'



function JobDetails(props) {

	const [showValidation , setShowValidation] = useState(false)

	useEffect(() => {
		console.log("Job data")
		console.log(props.job)
		if( props.job.type == "validation" && props.job.status == "Completed") {
			setShowValidation(true)
		}
	}, [])

	return(
			<div className="App-body-right-tab-job-details-modal">
				<div className="App-body-right-tab-job-details-modal-content">
					<div className="App-body-right-tab-job-details-modal-header">
			    		<div className="App-body-right-tab-job-details-modal-header-content">
			    			<div className="job_details_header">Job Details</div>
			    			<div className="job_id_subscript">{props.job.job_id}</div>
			    		</div>
			     		<div className="close" onClick={props.handleJobClose}>&times;</div>
			    	</div>
			    	<div className="App-body-right-tab-job-details-modal-body">
			    		<div className="App-body-right-tab-job-details-modal-body-details-container">
			    			<div className="App-body-right-tab-job-details-modal-body-details-1">
			    				<div className="App-body-right-tab-job-details-modal-body-detail-container">
			    					<div className="App-body-right-tab-job-details-modal-body-detail-header">
			    						Group Name | Dealer ID
			    					</div>
			    					<div className="App-body-right-tab-job-details-modal-body-detail-content">
			    						{props.job.tenant_name} | {props.job.dealer_id}
			    					</div>
			    				</div>
			    				<div className="App-body-right-tab-job-details-modal-body-detail-container">
			    					<div className="App-body-right-tab-job-details-modal-body-detail-header">
			    						Module
			    					</div>
			    					<div className="App-body-right-tab-job-details-modal-body-detail-content">
			    						{props.job.module} ( {props.job.sub_module} )
			    					</div>
			    				</div>
			    			</div>
			    			<div className="App-body-right-tab-job-details-modal-body-details-2">
			    				<div className="App-body-right-tab-job-details-modal-body-detail-container">
			    					<div className="App-body-right-tab-job-details-modal-body-detail-header">
			    						Triggered By
			    					</div>
			    					<div className="App-body-right-tab-job-details-modal-body-detail-content">
			    						<img src={userProfile} alt="userprofile" id="App-body-right-tab-job-details-modal-body-detail-user-profile"/>
			    						{props.job.user}
			    					</div>
			    				</div>
			    				<div className="App-body-right-tab-job-details-modal-body-detail-container">
			    					<div className="App-body-right-tab-job-details-modal-body-detail-header">
			    						Triggered At (UTC)
			    					</div>
			    					<div className="App-body-right-tab-job-details-modal-body-detail-content">
			    						{props.job.start_time}
			    					</div>
			    				</div>
			    			</div>
			    			<div className="App-body-right-tab-job-details-modal-body-details-3">
			    				<div className="App-body-right-tab-job-details-modal-body-detail-container">
			    					<div className="App-body-right-tab-job-details-modal-body-detail-header">
			    						Status
			    					</div>
			    					<div className="App-body-right-tab-job-details-modal-body-detail-content">
			    						<JobStatusLabel status={props.job.status} />
			    					</div>
			    				</div>
			    				<div className="App-body-right-tab-job-details-modal-body-detail-container">
			    					<div className="App-body-right-tab-job-details-modal-body-detail-header">
			    						Time Taken
			    					</div>
			    					<div className="App-body-right-tab-job-details-modal-body-detail-content">
			    						{props.job.execution_time}
			    					</div>
			    				</div>
			    			</div>
			    			<div className="App-body-right-tab-job-details-modal-body-details-4">
			    				<div className="App-body-right-tab-job-details-modal-body-detail-container">
			    					<div className="App-body-right-tab-job-details-modal-body-detail-header">
			    						Estimated Count
			    					</div>
			    					<div className="App-body-right-tab-job-details-modal-body-detail-content">
			    						{props.job.estimated_count}
			    					</div>
			    				</div>
			    				<div className="App-body-right-tab-job-details-modal-body-detail-container">
			    					<div className="App-body-right-tab-job-details-modal-body-detail-header">
			    						Extracted Count
			    					</div>
			    					<div className="App-body-right-tab-job-details-modal-body-detail-content">
			    						{props.job.actual_count}
			    					</div>
			    				</div>
			    			</div>
			    		</div>
			    		{showValidation && 
			    				<ValidationResult job={props.job} />
			    		}
			    		<div className="App-body-right-tab-job-details-modal-body-log-outer-container">
			    			<div className="App-body-right-tab-job-details-modal-body-log-header">
			    				<div className="App-body-right-tab-job-details-modal-body-log-header-content">
			    					Logs
			    				</div>
			    			</div>
			    			<div className="App-body-right-tab-job-details-modal-body-log-content">
			    				<Logs job_id={props.job.job_id} />
			    			</div>
			    		</div>
			    	</div>
			    	<div className="App-body-right-tab-job-details-modal-footer">
			    		<div className="App-body-right-tab-job-details-modal-close" onClick={props.handleJobClose}>
			    			<div className="App-body-right-tab-job-details-modal-close-label">
			    				Close
			    			</div>
			    		</div>
			    	</div>
				</div>
			</div>
		)

}

export default JobDetails