import '../css/jobsrunning.css';
import jobsRunLogo from '../jobsrun.svg';



function JobsRunning(props) {

  return (
    <div className="App-body-right-tab-dashboard-overview-job-run">
      <div className="App-body-right-tab-dashboard-overview-job-run-icon">
        <div className="App-body-right-tab-dashboard-overview-job-run-icon-container">
          <img src={jobsRunLogo} alt="job-run" className="App-body-right-tab-dashboard-overview-job-run-icon-logo"/>
        </div>
      </div>
      <div className="App-body-right-tab-dashboard-overview-job-run-content">
        <div className="App-body-right-tab-dashboard-overview-job-run-content-title">
          Jobs Running
        </div>
        <div className="App-body-right-tab-dashboard-overview-job-run-content-count">
          {props.job_run}
        </div>
      </div>
    </div>
  );
}

export default JobsRunning;
