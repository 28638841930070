import React, { useState, useEffect} from 'react';
import '../css/migratemodal.css'
import Spinner from './spinner'


function MigrateModal(props) {

	return(
			<div id="myModal" class="App-body-migrate-modal">
				<div class="App-body-migrate-modal-content">
					<div class="App-body-migrate-modal-header">
						<div class="App-body-migrate-modal-header-content">Migration Warning</div>
		     			<div class="close" onClick={props.handleClose}>&times;</div>
					</div>
					<div class="App-body-migrate-modal-body">
					
							<div class="App-body-migrate-modal-body-label">
								Confirm Migration and Proceed ?
							</div>
					</div>
					<div class="App-body-migrate-modal-footer">
			      		<div class="App-body-migrate-modal-footer-proceed" onClick={props.handleSuccess}>
					      	{ props.showLoader ? 
					      		<i class="fa fa-spinner fa-pulse"></i> 
					      		:
					      		<div>Proceed</div>
					      	}
			      		</div>
			      		<div class="App-body-migrate-modal-footer-cancel" onClick={props.handleClose}>
			      			Cancel
			      		</div>
			    </div>

				</div>
			</div>
		)

}

export default MigrateModal