import '../css/agentsoffline.css';
import userOfflineLogo from '../useroffline.svg';



function AgentsOffline(props) {

  return (
    <div className="App-body-right-tab-dashboard-overview-agent-offline">
      <div className="App-body-right-tab-dashboard-overview-agent-offline-icon">
        <div className="App-body-right-tab-dashboard-overview-agent-offline-icon-container">
          <img src={userOfflineLogo} alt="agent-offline" className="App-body-right-tab-dashboard-overview-agent-offline-icon-logo"/>
        </div>
      </div>
      <div className="App-body-right-tab-dashboard-overview-agent-offline-content">
        <div className="App-body-right-tab-dashboard-overview-agent-offline-content-title">
          Agents Offline
        </div>
        <div className="App-body-right-tab-dashboard-overview-agent-offline-content-count">
          {props.agent_offline}
        </div>
      </div>
    </div>
  );
}

export default AgentsOffline;
