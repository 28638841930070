import '../css/dealerstatusmodules.css'
import ModuleProgress from './moduleprogressbar'
import exclamation from '../exclamation.svg';
import { useState, useEffect } from 'react';


function StatusModulesNav(props) {

  const [showError, setShowError] = useState(false)

  function handleClick() {
    props.handleModuleClick(props.moduleValue)
  }

  useEffect(() => {
    if( props.selectedModule === props.moduleValue && props.showError ) {
      setShowError(true)
    }
  }, [props.showError])

  return (
      <div className={props.css} onClick={handleClick}>
            <div className="App-body-right-tab-status-accordion-details-module-name">
              {props.moduleName}
            </div>
            {showError ? <div className="App-body-right-tab-status-accordion-details-module-error">
                    <img src={exclamation} alt="error-exclamation" className="App-body-right-tab-status-accordion-details-module-error-exclamation"/>
              </div>
               : <div className="App-body-right-tab-status-accordion-details-module-error-dummy"></div>
            }
            <div className="App-body-right-tab-status-accordion-details-module-progress">
              <div className="App-body-right-tab-status-accordion-details-module-progres-bar">
                <ModuleProgress progress={props.progress} />
              </div>
            </div>
      </div>
  );
}

export default StatusModulesNav;
