import '../App.css';
import DashboardTitleToggle from './dashboardtitletoggle'


function DashboardTitle(props) {

  return (
    <div className="App-body-right-tab-dashboard-title">
      <div className="App-body-right-tab-dashboard-title-container">
          <div className="App-body-right-tab-dashboard-title-name">
            Document Extraction Dashboard
          </div>
          <div className="App-body-right-tab-dashboard-refresh-time">
            Last Refreshed Time : {props.lastRefreshTime}
          </div>
          <div className="App-body-right-tab-dashboard-refresh">
            {props.showSpinner ?
                <i class="fa fa-spinner" id="App-body-right-tab-dashboard-spinner-icon" aria-hidden="true"></i> 
              :
                <i class="fa fa-refresh" id="App-body-right-tab-dashboard-refresh-icon" aria-hidden="true" onClick={props.handleDashboardRefresh}></i>
            }
          </div>
      </div>
    </div>
  );
}

export default DashboardTitle;
