import React, { useState, useEffect } from 'react';
import './css/login.css'
import logo from './tekion-logo.svg'
import uploadLogo from './tekion-upload.png'

function Login(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;


	const [showLoader, setShowLoader] = useState(false)
	const [emailInputRef, setEmailInputRef] = useState('')
	const [passwordInputRef, setPasswordInputRef] = useState('')
	const [email, setEmail] = useState(emailInputRef.value)
	const [password, setPassword] = useState(passwordInputRef.value)


	function setEmailInputReference(el) {
   		setEmailInputRef(el)
  	}

  	function setPasswordReference(el) {
  		setPasswordInputRef(el)
  	}

  	function handleEmailInput() {
  		setEmail(emailInputRef.value)
  	}

  	function handlePassInput() {
  		setPassword(passwordInputRef.value)
  	}

  	function handleLogin() {
  		setShowLoader(true)
  		fetch(BASE_URL+'/v2/authenticate', {
        	method: 'POST',
        	headers: {
          		'Accept':'application/json',
          		'Content-Type':'application/json'
        	},
        	body : JSON.stringify({
          		user: email,
          		password: password
        	})  
      	}).then(res => res.json())
      	.then((data) => {
      		var response_obj = data['message']
        	var login_status = response_obj['status']
        	if (login_status === "Failed") {
          		setEmail("")
          		setPassword("")
          		props.update_login(true, "", "", "", "", "")
          		setShowLoader(false)
        	} else {
          		var apikey = response_obj['api_key']
          		var key = response_obj['key']
          		var user_name = response_obj['user_name']
          		var user_id = response_obj['user_id']
          		var role = response_obj['role']
          		props.update_login(false, apikey, key, user_name, user_id, role)
          		setShowLoader(false)
        	}
      	})
  	}

	return(
			<div className="App-login">
				<div className="App-login-left-tab">
					<div className="App-login-left-tab-container">
					<img src={uploadLogo} alt="logo" id="App-login-left-logo" />
					</div>
				</div>
				<div className="App-login-right-tab">
					<div className="App-login-right-tab-logo">
						<img src={logo} alt="logo" id="App-login-logo" />
					</div>
					<div className="App-login-right-tab-form">
						<div className="App-login-right-tab-form-container">
							<div className="App-login-right-tab-form-email">
								<div className="App-login-right-tab-form-email-label">
									Username
								</div>
								<input ref={el => setEmailInputReference(el)} placeholder="Type Here" onInput={handleEmailInput} className="App-login-right-tab-form-email-box">
								</input>
							</div>
							<div className="App-login-right-tab-form-password">
								<div className="App-login-right-tab-form-password-label">
									Password
								</div>
								<input type="password" ref={el => setPasswordReference(el)} placeholder="Type Here" onInput={handlePassInput} className="App-login-right-tab-form-password-box">
								</input>
							</div>
							<div className="App-login-right-tab-form-submit">
								<div className="App-login-right-tab-form-submit-button" onClick={handleLogin}>
									<div className="App-login-right-tab-form-submit-button-label">
										{showLoader ? 
												<i class="fa fa-spinner fa-pulse"></i> 
											:
												<div>Submit</div>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
}

export default Login