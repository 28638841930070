import '../App.css';

function Icon(props) {

  function handleClick() {
    props.handleClick(props.name);
  }

  return (
    <div className="App-body-block-left-tab-icon" onClick={handleClick}>
      <div className="App-body-block-left-tab-icon-title">
        {props.name[0]}
      </div>
    </div>
  );
}

export default Icon;
