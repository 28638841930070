import '../css/agentstatus.css';
import React, { useState, useEffect } from 'react';


function AgentStatus(props) {

  const [className, setClassName] = useState("")
  const [agentStatus, setAgentStatus] = useState("")

  useEffect(() => {
    if (props.status === "live") {
      let selectedClassName = "App-body-right-tab-agent-content-status-icon-online"
      setClassName(selectedClassName)
      setAgentStatus("Online")
    } else {
      let selectedClassName = "App-body-right-tab-agent-content-status-icon-offline"
      setClassName(selectedClassName)
      setAgentStatus("Offline")
    }
  }, [props.status])

  return (
      <div className="App-body-right-tab-agent-content-status">
        <div className={className}>
        </div>
        <div className="App-body-right-tab-agent-content-status-overview">
          {agentStatus}
        </div>
      </div>
  );
}

export default AgentStatus;
