import React, { useState, useEffect} from 'react';
import '../css/addjobmodal.css'
import ErrorModal from './errormodal'


function AddJobModal(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [years, setYears ] = useState([])
	const [selectedYear, setSelectedYear] = useState('')
	const [showLoader, setShowLoader] = useState(props.showLoader)

	useEffect(() => {
		setShowLoader(props.showLoader)
		var session_apikey = localStorage.getItem('drm_session_apikey')
		fetch(BASE_URL + '/v1/jobs/all_years', {
				method : 'GET',
				headers : {
					'x-api-key' : session_apikey
			}
		})
		.then(res => res.json())
		.then((data) => {
			setYears(data['message'])
		})
		.catch(console.log)
	}, [props.showLoader])

	function handleYearChange(event) {
		var year = event.target.value
		setSelectedYear(year)
	}

	function handleAddJob() {
		props.handleAddJob(selectedYear)
	}

	function handleErrorClose() {
		props.handleErrorClose()
	}

	return(
			<div id="myModal" class="App-body-status-year-add-job-modal">
			  {props.showError ? 
			  	<ErrorModal message={props.errorMsg} handleErrorClose={handleErrorClose} /> 
			  	: 
			  	<div></div>
			  }
			  <div class="App-body-status-year-add-job-modal-content">
			    <div class="App-body-status-year-add-job-modal-header">
			    	<div class="App-body-status-year-add-job-modal-header-content">Add Year to Extract Metadata</div>
			     	<div class="close" onClick={props.handleAddClose}>&times;</div>
			    </div>
			    <div class="App-body-status-year-add-job-modal-body">
			      <div class="App-body-status-year-add-job-modal-body-label">
			      	Please select the year from which you want to extract metadata
			      </div>
			      <div class="App-body-status-year-add-job-modal-body-year-dropdown-container">
			      	<div class="App-body-status-year-add-job-modal-body-year-dropdown-label">
			      		Year
			      	</div>
			      	<select className="App-body-status-year-add-job-modal-body-year-dropdown" onChange={handleYearChange}  name="year" id="year">
              			{years.map((year, index) => (
               				 <option key={index} value={year}>{year}</option>
          				))}
        			</select>
			      </div>
			    </div>
			    <div class="App-body-status-year-add-job-modal-footer">
			      <div class="App-body-status-year-add-job-modal-footer-proceed" onClick={handleAddJob}>
			      	{ props.showLoader ? 
			      		<i class="fa fa-spinner fa-pulse"></i> 
			      		:
			      		<div>Proceed</div>
			      	}
			      </div>
			      <div class="App-body-status-year-add-job-modal-footer-cancel" onClick={props.handleAddClose}>
			      	Cancel
			      </div>
			    </div>
			  </div>

			</div>
		)

}

export default AddJobModal;