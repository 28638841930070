import '../App.css';
import React from 'react';
import ProgressBar from './progressBar'

function DashboardBodyContentProgressBar(props) {

  return (
    <div>
      <div class="bubble-handler">
        <div class="bubble">
            <div class="bubble-content">
              <div>Job Count : {props.extractionData.job_count}</div>
              <div>Estimated Docs : {props.extractionData.total_estimated_count}</div>
              <div>Extracted Docs: {props.extractionData.total_actual_count ? props.extractionData.total_actual_count : 0}</div>
            </div>
        </div>
        <ProgressBar progress={props.extractionData.progress} />
      </div>
    </div>
  );
}

export default DashboardBodyContentProgressBar;
