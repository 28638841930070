import React, { useState, useEffect } from 'react';
import '../css/migrationactionitem.css'

function MigrationActionItem(props) {

	const [showLabel, setShowLabel] = useState(true)
	const [labelTitle , setLableTitle] = useState("Extract Metadata")

	useEffect(() => {
		if(props.currentState != "") {
			if( props.currentState === "metadata_start" || props.currentState === "document_start" 
				|| props.currentState === "migration_start" || props.currentState === "validation_start" 
				|| props.currentState === "metadata_fail" || props.currentState === "document_fail" 
				|| props.currentState === "migration_fail" || props.currentState === "validation_fail") {
				setShowLabel(false)
			}
			switch(props.currentState) {
				case "metadata_start" :
					setLableTitle("Extract Metadata ")
					break;
				case "metadata_run" :
					setLableTitle("Extracting Metadata...")
					break;
				case "metadata_fail" :
					setLableTitle("Re-extract Metadata")
					break;
				case "metadata_complete":
				case "document_start" :
					setLableTitle("Extract Documents")
					break;
				case "document_run" :
					setLableTitle("Extacting Documents...")
					break;
				case "document_fail":
					setLableTitle("Re-extract Documents")
					break;
				case "document_complete":
				case "validation_start":
					setLableTitle("Validate Documents")
					break;
				case "validation_run":
					setLableTitle("Validating Documents...")
					break;
				case "validation_fail":
					setLableTitle("Re-validate Documents")
					break;
				case "validation_complete":
				case "migration_start":
					setLableTitle("Migrate Documents")
					break;
			}
		}

	}, [props.currentState])

	function handleClick() {
		props.handleJobClick()
	}

	return(
		<div class="App-body-right-tab-year-action-container">
			{showLabel == true ? 
				<div class="App-body-right-tab-year-action-label">
					{labelTitle}
				</div>
				: 
					<div className="App-body-right-tab-year-action-button" onClick={handleClick}>
						<div class="App-body-right-tab-year-action-button-label">
							{props.showLoader ?
								<i class="fa fa-spinner fa-pulse"></i>
							:
								<div>
									{labelTitle}
								</div>
							}
						</div>
				 	</div>
			}
		</div>
	)
}

export default MigrationActionItem