import React, { useState, useEffect} from 'react';
import '../css/errormodal.css'
import Spinner from './spinner'


function MigrationModal(props) {

	const [showLoader, setShowLoader] = useState(true)

	useEffect(() => {
		if(props.message != '') {
			setShowLoader(false)
		}
	}, [props.message])

	return(
			<div id="myModal" class="App-body-error-modal">
				<div class="App-body-error-modal-content">
					<div class="App-body-error-modal-header">
						<div class="App-body-error-modal-header-content">Migration Result</div>
		     			<div class="close" onClick={props.handleErrorClose}>&times;</div>
					</div>
					<div class="App-body-error-modal-body">
					{
						showLoader ? 
							<Spinner />
						:
							<div class="App-body-error-modal-body-label">
								{props.message}
							</div>
					}
					</div>
				</div>
			</div>
		)

}

export default MigrationModal