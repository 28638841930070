import React, { useState, useEffect } from 'react';
import ProgressIcon from './yearprogressicon'

function DocumentProgressIcon(props) {

  const [iconStatus, setIconStatus] = useState("")

  useEffect(() => {
    if ( props.currentState.startsWith("metadata_")) {
      setIconStatus("Disabled")
    } else {
      if (props.currentState == "document_start") {
        setIconStatus("Start")
      } else if ( props.currentState == "document_run") {
        setIconStatus("Running")
      } else if ( props.currentState == "document_fail") {
        setIconStatus("Failed")
      } else {
        setIconStatus("Completed")
      }
    }
  }, [props.currentState])

  function handleOnClick() {
    console.log("Icon clicked")
    props.onClick(props.job_data)
  }

  return(
      <ProgressIcon currentState={iconStatus} onClick={handleOnClick} />
    );
}

export default DocumentProgressIcon