import StatusBodyYearProgressTitle from './statusbodyyearprogresstitle'
import StatusBodyYearProgressTable from './statusbodyyearprogresstable'
import { useState, useEffect } from 'react';


function StatusBodyYearProgressContainer(props) {

  return (
      <div className="App-body-right-tab-year-progress-body-container">
        <StatusBodyYearProgressTitle />
        <StatusBodyYearProgressTable 
          extractionData={props.extractionData} 
          subModule={props.subModule}
          showError={props.showError}
          module={props.module}
          handleRefresh={props.handleRefresh}
          runningJobsCount={props.runningJobsCount}
          dealer_status={props.dealer_status} />
      </div>
  );
}

export default StatusBodyYearProgressContainer;
