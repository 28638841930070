import React, { useState, useEffect} from 'react';
import '../css/statusbodyaccordion.css';
import '../css/statusbodystatustable.css'
import DealerStatusDetails from './dealerstatusdetails'
import ProgressBar from './progressBar'
import DealerStatusLabel from './dealerstatuslabel'
import infoLogo from '../info.svg';
import RunningJobs from './runningjobs'
import AdditionalInsights from './additionalinsights'
import overFlowLogo from '../overflow.svg';


const DealerStatusAccordion = ({ dealer_status, content, refreshData }) => {
  const [isActive, setIsActive] = useState(false);

  const [isRunState, setIsRunState] = useState(false)

  const [showRunningJobs, setShowRunningJobs] = useState(false)

  const [showAddInfo , setShowAddInfo] = useState(false)

  const [showMigrationLoader, setShowMigrationLoader] = useState(false)


  useEffect(() => {
    if(dealer_status.status === "Jobs Triggered" || dealer_status.status === "Validation Triggered") {
      setIsRunState(true)
    }
  }, [])

  function handleInfoClick(event) {
    console.log("hello")
    event.stopPropagation()
    setShowRunningJobs(true)
  }

  function handleRunJobClose() {
    setShowRunningJobs(false)
  }

  function handleAddInfo(event) {
    console.log("asdas")
    setShowAddInfo(!showAddInfo)
    event.stopPropagation()
  } 

  function handleAddInfoClose() {
    setShowAddInfo(false)
  }


  function handleAccordionClick() {
    setIsActive(!isActive)
    if ( showAddInfo) {
      setShowAddInfo(false)
    }
  }



  return (
    <div className="App-body-right-tab-status-table-status-accordion-item">
      <div className="App-body-right-tab-status-table-status-accordion-title" onClick={() => handleAccordionClick()}>
        <div className="App-body-right-tab-status-table-status-content" id="App-body-right-tab-status-table-collapse-icon">
          <div className="App-body-right-tab-status-table-status-content-detail">
            {isActive ? <i class="icon-caret-down"/> : <i class="icon-caret-right"/>}
          </div>
        </div>
        <div className="App-body-right-tab-status-table-status-content" id="App-body-right-tab-status-table-group-name">
          <div className="App-body-right-tab-status-table-status-content-detail">
            {dealer_status.tenant_display_name}
          </div>
        </div>
        <div className="App-body-right-tab-status-table-status-content" id="App-body-right-tab-status-table-dealership-name">
          <div className="App-body-right-tab-status-table-status-content-detail">
            {dealer_status.dealer_display_name}
          </div>
        </div>
        <div className="App-body-right-tab-status-table-status-content" id="App-body-right-tab-status-table-dealer-id">
          <div className="App-body-right-tab-status-table-status-content-detail" id="App-body-right-tab-status-table-dealer-id-content">
            {dealer_status.dealer_id}
          </div>
        </div>
        <div className="App-body-right-tab-status-table-status-content" id="App-body-right-tab-status-table-dms-type">
          <div className="App-body-right-tab-status-table-status-content-detail">
            {dealer_status.type}
          </div>
        </div>
        <div className="App-body-right-tab-status-table-status-content" id="App-body-right-tab-status-table-go-live-date">
          <div className="App-body-right-tab-status-table-status-content-detail">
            {dealer_status.launch_date}
          </div>
        </div>
        <div className="App-body-right-tab-status-table-status-content" id="App-body-right-tab-status-table-last-updated">
          <div className="App-body-right-tab-status-table-status-content-detail">
            {dealer_status.updated_time}
          </div>
        </div>
        <div className="App-body-right-tab-status-table-status-content" id="App-body-right-tab-status-table-status">
          <div className="App-body-right-tab-status-table-status-content-detail" id="App-body-right-tab-status-table-status-contents">
            <DealerStatusLabel status={dealer_status.status} />
            {isRunState ? <img src={infoLogo} alt="info-logo" id="App-body-right-tab-status-table-status-contents-info" onClick={handleInfoClick}/>  : <div></div>}
          </div>
        </div>
        <div className="App-body-right-tab-status-table-status-content" id="App-body-right-tab-status-table-overflow">
          <div className="App-body-right-tab-status-table-status-content-detail" id ="App-body-right-tab-status-table-add-info">
            <img src={overFlowLogo} alt="additional-info" id="App-body-right-tab-status-table-status-add-info" onClick={handleAddInfo} />
          </div>
        </div>
      </div>
      {showAddInfo && <AdditionalInsights refreshData={refreshData} onClick={handleAddInfo} handleAddInfoClose={handleAddInfoClose} dealer_status={dealer_status} />}
      {isActive && 
        <div className="App-body-right-tab-status-table-status-accordion-content">
          <DealerStatusDetails dealer_status={dealer_status} />
        </div>
      }
      {
        showRunningJobs && 
          <RunningJobs handleClose={handleRunJobClose} 
                      tenant_display_name={dealer_status.tenant_display_name} 
                      tenant_name={dealer_status.tenant_name}
                      dealer_id={dealer_status.dealer_id} 
                      dms_type={dealer_status.type} 
          />
      }
    </div>
  );
};

export default DealerStatusAccordion;