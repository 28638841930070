import '../css/dashboardbodyoverview.css';
import DocumentCount from './documentCount'
import JobsRunning from './jobsrunning'
import JobsFail from './jobsfail'
import AgentsOnline from './agentsonline'
import AgentsOffline from './agentsoffline'
import RiskStores from './riskstores'

function DashboardBodyOverviewContents(props) {

  return (
    <div className="App-body-right-tab-dashboard-overview-content-container">
        <div className="App-body-right-tab-dashboard-overview-contents">
            <DocumentCount document_count={props.documentCount} />
            <JobsRunning job_run={props.jobStatus.running} />
            <JobsFail job_fail={props.jobStatus.failed} />
            <AgentsOnline agent_online={props.agentStatus.live} />
            <AgentsOffline agent_offline={props.agentStatus.failed} />
            <RiskStores stores_completed={props.storeCount} />
        </div>
    </div>
  );
}

export default DashboardBodyOverviewContents;
