import React, { useState, useEffect} from 'react';
import JobsStatusLabel from './jobsstatuslabel'
import documentIcon from '../document.svg'
import userProfile from '../userprofile.svg';
import '../css/joblogrow.css'


function JobLogRow(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [showSpinner, setShowSpinner] = useState(false)

	function handleCancel() {
		setShowSpinner(true)
		console.log('hello')
		console.log(showSpinner)
		var apikey = localStorage.getItem('drm_session_apikey')
		fetch(BASE_URL+"/v1/jobs/" + props.job.job_id +'/cancel', {
				method : 'POST',
				headers:{
					'x-api-key': apikey
				}
		}).then(res => res.json())
			.then((data) => {
				setShowSpinner(false)
		    	props.refreshJob()
		 }).catch(console.log)
	}

	function handleJobLog() {
		props.handleJobDetail(props.job)
	}

	return(
			<div className="App-body-right-tab-running-jobs-logs-table-body-row" key={props.index} >
 				<div className="App-body-right-tab-running-jobs-logs-table-body-row-name" id="App-body-right-tab-running-jobs-logs-table-module">
					<div className="App-body-right-tab-running-jobs-logs-table-body-row-content">
						{props.job.module} {props.job.sub_module}
					</div>
				</div>
				<div className="App-body-right-tab-running-jobs-logs-table-body-row-name" id="App-body-right-tab-running-jobs-logs-table-agent">
					<div className="App-body-right-tab-running-jobs-logs-table-body-row-content">
						{props.job.agent_name}
					</div>
				</div>
				<div className="App-body-right-tab-running-jobs-logs-table-body-row-name" id="App-body-right-tab-running-jobs-logs-table-year">
					<div className="App-body-right-tab-running-jobs-logs-table-body-row-content">
						{props.job.year.join()}
					</div>
				</div>
				<div className="App-body-right-tab-running-jobs-logs-table-body-row-name" id="App-body-right-tab-running-jobs-logs-table-last-updated">
					<div className="App-body-right-tab-running-jobs-logs-table-body-row-content">
						{props.job.timestamp}
					</div>
				</div>
				<div className="App-body-right-tab-running-jobs-logs-table-body-row-name" id="App-body-right-tab-running-jobs-logs-table-time-taken">
					<div className="App-body-right-tab-running-jobs-logs-table-body-row-content">
						{props.job.execution_time}
					</div>
				</div>
				<div className="App-body-right-tab-running-jobs-logs-table-body-row-name" id="App-body-right-tab-running-jobs-logs-table-triggered-by">
					<div className="App-body-right-tab-running-jobs-logs-table-body-row-content" id="App-body-right-tab-job-details-modal-body-detail-user-profile">
						<img src={userProfile} alt="userprofile" id="App-body-right-tab-job-details-modal-body-detail-user-profile-image" />
						{props.job.user}
					</div>
				</div>
				<div className="App-body-right-tab-running-jobs-logs-table-body-row-name" id="App-body-right-tab-running-jobs-logs-table-status">
					<div className="App-body-right-tab-running-jobs-logs-table-body-row-content">
						<JobsStatusLabel status={props.job.status} />
					</div>
				</div>
				<div className="App-body-right-tab-running-jobs-logs-table-body-row-name" id="App-body-right-tab-running-jobs-logs-table-logs">
					<div className="App-body-right-tab-running-jobs-logs-table-body-row-content">
						<img src={documentIcon} alt="Show Logs" onClick={handleJobLog}/>
					</div>
				</div>
				<div className="App-body-right-tab-running-jobs-logs-table-body-row-name" id="App-body-right-tab-running-jobs-logs-table-cancel-button">
						<div className="App-body-right-tab-running-jobs-logs-table-body-row-cancel-job" onClick={handleCancel}>
							{showSpinner ? 
									<div className="App-body-right-tab-running-jobs-logs-table-body-row-cancel-job-label">
									<i class="fa fa-spinner fa-pulse"></i>
									</div>
								:
									<div className="App-body-right-tab-running-jobs-logs-table-body-row-cancel-job-label">
										Cancel Job
									</div>
							}
						</div>
				</div>
			</div>
		)

}

export default JobLogRow