import '../css/agent.css';

function AgentTitle(props) {

  return (
    <div className="App-body-right-tab-agent-title">
      <div className="App-body-right-tab-agent-title-container">
        <div className="App-body-right-tab-agent-title-name">
            Document Extraction Agents
        </div>
        <div className="App-body-right-tab-dashboard-refresh">
            {props.showSpinner ?
                <i class="fa fa-spinner" id="App-body-right-tab-dashboard-spinner-icon" aria-hidden="true"></i> 
              :
                <i class="fa fa-refresh" id="App-body-right-tab-dashboard-refresh-icon" aria-hidden="true" onClick={props.handleDashboardRefresh}></i>
            }
        </div>
      </div>
    </div>
  );
}

export default AgentTitle;
