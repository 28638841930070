import React, { useState, useEffect } from 'react';
import '../css/countvalidation.css'

function CountValidation(props) {

	return(
			<div className="App-body-count-validation-modal">
				<div className="App-body-count-validation-modal-content">
					<div className="App-body-count-validation-modal-header">
						<div className="App-body-count-validation-modal-header-content">Count Validation Results</div>
						<div className="close" onClick={props.handleJobClose}>&times;</div>
					</div>
					<div className="App-body-count-validation-modal-body">
						<div className="App-body-count-validation-modal-body-container">
							<div className="App-body-count-validation-modal-body-table-header">
								<div className="App-body-count-validation-modal-body-log-table-title-content" id="App-body-count-validation-modal-title-module">
          							<div className="App-body-right-tab-job-details-modal-body-log-table-title-content-detail">
          								Module
          							</div>
          						</div>
								<div className="App-body-right-tab-job-details-modal-body-log-table-title-content" id="App-body-count-validation-modal-title-actual-count">
          							<div className="App-body-right-tab-job-details-modal-body-log-table-title-content-detail">
          								Actual Count
          							</div>
          						</div>
          						<div className="App-body-right-tab-job-details-modal-body-log-table-title-content" id="App-body-count-validation-modal-title-available-count">
          							<div className="App-body-right-tab-job-details-modal-body-log-table-title-content-detail">
          								Available Count
          							</div>
          						</div>
          						<div className="App-body-right-tab-job-details-modal-body-log-table-title-content" id="App-body-count-validation-modal-title-metadata-count">
          							<div className="App-body-right-tab-job-details-modal-body-log-table-title-content-detail">
          								MetaData Count
          							</div>
          						</div>
          						<div className="App-body-right-tab-job-details-modal-body-log-table-title-content" id="App-body-count-validation-modal-title-validated">
          							<div className="App-body-right-tab-job-details-modal-body-log-table-title-content-detail">
          								Validated
          							</div>
          						</div>
							</div>
							<div className="App-body-count-validation-modal-body-table-body">
								<div className="App-body-count-validation-modal-body-log-table-content" id="App-body-count-validation-modal-title-module">
          							<div className="App-body-right-tab-job-details-modal-body-log-table-title-content-detail">
          								{props.validationData.module}
          							</div>
          						</div>
          						<div className="App-body-count-validation-modal-body-log-table-content" id="App-body-count-validation-modal-title-actual-count">
          							<div className="App-body-right-tab-job-details-modal-body-log-table-title-content-detail">
          								{props.validationData.insights.actual_count}
          							</div>
          						</div>
          						<div className="App-body-count-validation-modal-body-log-table-content" id="App-body-count-validation-modal-title-available-count">
          							<div className="App-body-right-tab-job-details-modal-body-log-table-title-content-detail">
          								{props.validationData.insights.available_count}
          							</div>
          						</div>
          						<div className="App-body-count-validation-modal-body-log-table-content" id="App-body-count-validation-modal-title-metadata-count">
          							<div className="App-body-right-tab-job-details-modal-body-log-table-title-content-detail">
          								{props.validationData.insights.metadata_count ? props.validationData.insights.metadata_count  : 0 }
          							</div>
          						</div>
          						<div className="App-body-count-validation-modal-body-log-table-content" id="App-body-count-validation-modal-title-validated">
          							<div className="App-body-right-tab-job-details-modal-body-log-table-title-content-detail">
          								{props.validationData.count_status ? 
							 						<i class="fa fa-check tekion-success-color" aria-hidden="true"></i>
							 					: 
							 						<i class="fa fa-times tekion-fail-color" aria-hidden="true"></i>
							 				}
          							</div>
          						</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
}

export default CountValidation