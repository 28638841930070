import '../css/progressBar.css';
import React, { useState, useEffect } from 'react';


function ProgressBar(props) {

	let progressBarCss = {
						"height" : "5px",
					  	"width" : "90%",
					  	"display": "flex",
					  	"msFlexDirection": "column",
					  	"flexDirection": "column",
					  	"msFlexPack": "center",
					 	"justifyContent": "center",
					  	"color": "#fff",
					  	"backgroundColor" : "red",
					  	"textAlign": "center",
					  	"whiteSpace": "nowrap"
					}

	const [progressBarStyle, setProgressBarStyle] = useState(progressBarCss);
	const [progressBarStatus, setProgressBarStatus] = useState("");
	const [progressPercent, setProgressPercent] = useState('')

	useEffect(() => {
		let progressPercentage = "0"
		if (!isNaN(props.progress) && props.progress != "") {
			setProgressPercent(props.progress)
			progressPercentage = props.progress / 100
			let newProgressBarStyle = Object.assign({}, progressBarCss);
			if ( props.progress > 100) {
				newProgressBarStyle['width'] = "100%"
			} else {
				newProgressBarStyle['width'] = props.progress + "%"
			}
			if (progressPercentage > 0.7) {
				setProgressBarStatus("On Track")
				newProgressBarStyle['backgroundColor'] = "#5fd156"
				setProgressBarStyle(newProgressBarStyle)
			} else if ( progressPercentage > 0.5) {
				setProgressBarStatus("Delayed")
				newProgressBarStyle['backgroundColor'] = "#ff8800"
				setProgressBarStyle(newProgressBarStyle)
			} else {
				setProgressBarStatus("At Risk")
				newProgressBarStyle['backgroundColor'] = "#f52a08"
				setProgressBarStyle(newProgressBarStyle)
			}
		} else {
			let newProgressBarStyle = Object.assign({}, progressBarCss);
			setProgressPercent("0")
			setProgressBarStatus("At Risk")
			newProgressBarStyle['backgroundColor'] = "#f52a08"
			newProgressBarStyle['width'] = "1%"
			setProgressBarStyle(newProgressBarStyle)
		}
	} ,[])

	return(
		<div className="progress-bar-container">
			<div class="progress-bar-details">
				<div class="progress-bar-status">
					{progressBarStatus}
				</div>
				<div class="progress-bar-percentage">
				  {progressPercent + "%"}
				</div>
			</div>
			<div class="progress-bar-envelope"> 
				<div style={progressBarStyle}></div>
			</div>
		</div>
	);

}

export default ProgressBar