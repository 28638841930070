import '../App.css';
import Dashboard from './dashboard'
import Agent from './agent'
import Status from './status'

function RightTab(props) {

  return (
    <div className="App-body-block-right-tab-container">
      {props.selectedTab === "Dashboard" ? <Dashboard /> : <div></div>}
      {props.selectedTab === "Agent" ? <Agent /> : <div></div>}
      {props.selectedTab === "Status" ? <Status /> : <div></div>}
    </div>
  );
}

export default RightTab;
