import React, { useState, useEffect} from 'react';
import '../css/runningjobs.css'
import JobLogs from './joblogs'

function RunningJobs(props) {

	return(
			<div className="App-body-right-tab-running-jobs-modal">
				<div className="App-body-right-tab-running-jobs-modal-content">
					<div className="App-body-right-tab-running-jobs-modal-header">
			    		<div className="App-body-right-tab-running-jobs-modal-header-content">Running Jobs Info</div>
			     		<div className="close" onClick={props.handleClose}>&times;</div>
			    	</div>
			    	<div className="App-body-right-tab-running-jobs-modal-body">
			    		<div className="App-body-right-tab-running-jobs-modal-body-job-details">
			    			<div className="App-body-right-tab-running-jobs-modal-body-job-detail-group">
			    				<div className="App-body-right-tab-jrunning-jobs-modal-body-detail-container">
			    					<div className="App-body-right-tab-running-jobs-modal-body-job-detail-group-caption">
			    						
			    							Group Name (Tenant)
			    						
			    					</div>
			    					<div className="App-body-right-tab-running-jobs-modal-body-job-detail-group-value">
			    						{props.tenant_display_name}
			    					</div>
			    				</div>
			    			</div>
			    			<div className="App-body-right-tab-running-jobs-modal-body-job-detail-group">
			    				<div className="App-body-right-tab-jrunning-jobs-modal-body-detail-container">
				    				<div className="App-body-right-tab-running-jobs-modal-body-job-detail-group-caption">
				    					Dealer ID
				    				</div>
				    				<div className="App-body-right-tab-running-jobs-modal-body-job-detail-group-value">
				 							{props.dealer_id}
				    				</div>
			    				</div>
			    			</div>
			    			<div className="App-body-right-tab-running-jobs-modal-body-job-detail-group">
			    				<div className="App-body-right-tab-jrunning-jobs-modal-body-detail-container">
				    				<div className="App-body-right-tab-running-jobs-modal-body-job-detail-group-caption">
				    					DMS Type
				    				</div>
				    				<div className="App-body-right-tab-running-jobs-modal-body-job-detail-group-value">
				    					{props.dms_type}
				    				</div>
			    				</div>
			    			</div>
			    		</div>
			    		<div className="App-body-right-tab-running-jobs-modal-body-job-logs">
			    			<JobLogs tenant_name={props.tenant_name} dealer_id={props.dealer_id} dms_type={props.dms_type} />
			    		</div>
			    	</div>
				</div>
			</div>
		)
}

export default RunningJobs