import React, { useState, useEffect} from 'react';
import '../css/jobinsights.css'
import DocumentInsightStatus from './documentinsightstatus'
import Spinner from './spinner'


function JobInsights(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [insights, setInsights] = useState({})
	const [showLoader, setShowLoader] = useState(true)


	useEffect(() => {
		var session_apikey = localStorage.getItem('drm_session_apikey')
		fetch(BASE_URL + '/v1/status/insights?tenant_name=' 
			+ props.dealer_status.tenant_name 
			+ '&dealer_id=' + props.dealer_status.dealer_id, {
				method : 'GET',
				headers : {
					'x-api-key' : session_apikey
			}
		})
		.then(res => res.json())
		.then((data) => {
			setInsights(data['message'])
			setShowLoader(false)
		})
		.catch(console.log)

	}, [props.dealer_status])

	return (
		<div class="App-body-status-year-job-insights-modal">
			<div class="App-body-status-year-job-insights-modal-content">
				<div class="App-body-status-year-job-insights-modal-header">
					<div class="App-body-status-year-job-insights-modal-header-content">Insights</div>
			     	<div class="close" onClick={props.handleInsightClose}>&times;</div>
				</div>
				<div class="App-body-status-year-job-insights-modal-body">
				{showLoader ? 
						<Spinner />
					:
					<div>
					<div class="App-body-status-year-job-insights-modal-body-overview">
						<div class="App-body-status-year-job-insights-modal-body-overview-container">
							<div class="App-body-status-year-job-insights-modal-body-overview-group-name">
								<div class="App-body-status-year-job-insights-modal-body-overview-details-header">
									Group Name
								</div>
								<div class="App-body-status-year-job-insights-modal-body-overview-details-value">
									{props.dealer_status.dealer_display_name}
								</div>
							</div>
							<div class="App-body-status-year-job-insights-modal-body-overview-dealer-id">
								<div class="App-body-status-year-job-insights-modal-body-overview-details-header">
									Dealer ID
								</div>
								<div class="App-body-status-year-job-insights-modal-body-overview-details-value">
									{props.dealer_status.dealer_id}
								</div>
							</div>
							<div class="App-body-status-year-job-insights-modal-body-overview-dms-type">
								<div class="App-body-status-year-job-insights-modal-body-overview-details-header">
									DMS Type
								</div>
								<div class="App-body-status-year-job-insights-modal-body-overview-details-value">
									{props.dealer_status.type}
								</div>
							</div>
						</div>
					</div>
					<div class="App-body-status-year-job-insights-modal-body-agent-status">
						<div class="App-body-status-year-job-insights-modal-body-agent-status-title">
							Agent Status
						</div>
						<div class="App-body-status-year-job-insights-modal-body-agent-status-table">
							<div class="App-body-status-year-job-insights-modal-body-agent-status-table-title">
								<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content" id="App-body-status-year-job-insights-modal-body-agent-status-agent-count">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content" id="App-body-status-year-job-insights-modal-body-agent-status-agent-live">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								Live
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content" id="App-body-status-year-job-insights-modal-body-agent-status-agent-failed">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								Failed
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content" id="App-body-status-year-job-insights-modal-body-agent-status-agent-total">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								Total
          							</div>
          						</div>
							</div>
							<div class="App-body-status-year-job-insights-modal-body-agent-status-table-details">
								<div className="App-body-status-year-job-insights-modal-body-agent-status-detail-content" id="App-body-status-year-job-insights-modal-body-agent-status-agent-count-value">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								Count
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-agent-status-detail-content" id="App-body-status-year-job-insights-modal-body-agent-status-agent-live-value">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								{insights.live_agents}
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-agent-status-detail-content" id="App-body-status-year-job-insights-modal-body-agent-status-agent-failed-value">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								{insights.total_agents - insights.live_agents}
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-agent-status-detail-content" id="App-body-status-year-job-insights-modal-body-agent-status-agent-total-value">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								{insights.total_agents}
          							</div>
          						</div>
							</div>
						</div>
					</div>
					<div class="App-body-status-year-job-insights-modal-body-job-status">
						<div class="App-body-status-year-job-insights-modal-body-agent-status-title">
							Job Status
						</div>
						<div class="App-body-status-year-job-insights-modal-body-agent-status-table">
							<div class="App-body-status-year-job-insights-modal-body-agent-status-table-title">
								<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content" id="App-body-status-year-job-insights-modal-body-agent-status-agent-count">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content" id="App-body-status-year-job-insights-modal-body-agent-status-job-run">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								Running
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content" id="App-body-status-year-job-insights-modal-body-agent-status-job-completed">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								Completed
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content" id="App-body-status-year-job-insights-modal-body-agent-status-job-fail">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								Failed
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content" id="App-body-status-year-job-insights-modal-body-agent-status-job-cancel">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								Cancelled
          							</div>
          						</div>
							</div>
							<div class="App-body-status-year-job-insights-modal-body-agent-status-table-details">
								<div className="App-body-status-year-job-insights-modal-body-agent-status-detail-content" id="App-body-status-year-job-insights-modal-body-agent-status-agent-count-value">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								Count
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-agent-status-detail-content" id="App-body-status-year-job-insights-modal-body-agent-status-job-run-value">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								{insights.live_jobs}
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-agent-status-detail-content" id="App-body-status-year-job-insights-modal-body-agent-status-job-completed-value">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								{insights.completed_jobs}
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-agent-status-detail-content" id="App-body-status-year-job-insights-modal-body-agent-status-job-fail-value">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								{insights.failed_jobs}
          							</div>
          						</div>
          						<div className="App-body-status-year-job-insights-modal-body-agent-status-detail-content" id="App-body-status-year-job-insights-modal-body-agent-status-job-cancel-value">
          							<div className="App-body-status-year-job-insights-modal-body-agent-status-title-content-detail">
          								{insights.cancelled_jobs}
          							</div>
          						</div>
							</div>
						</div>
					</div>
					<DocumentInsightStatus insights={insights} />
					</div>
				}
				</div>
				<div class="App-body-status-year-job-insights-modal-footer">
					<div class="App-body-status-year-job-insights-modal-footer-cancel" onClick={props.handleInsightClose}>
			      	Cancel
			      </div>
				</div>
			</div>
		</div>
	)
}

export default JobInsights