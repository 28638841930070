import React from 'react';
import LogTableTitle from './logtabletitle'
import LogTableContents from './logtablecontents'
import '../css/logs.css'


function Logs(props) {
	
	return(
			<div className="App-body-right-tab-job-details-modal-body-log-container">
				
					<div className="App-body-right-tab-job-details-modal-body-log-table-container">
						<LogTableTitle />
						<LogTableContents job_id={props.job_id} />
					</div>
				
			</div>
		)
}

export default Logs