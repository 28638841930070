import React, { useState, useEffect} from 'react';
import '../css/dealerstatuslabel.css'

function JobStatusLabel(props) {

	const [iconColor, setIconColor] = useState('App-body-right-tab-status-lable-icon')
	const [statusLabel, setStatusLabel] = useState('Running')

	useEffect(() => {
		switch(props.status) {
			case "Running":
				setStatusLabel('Running')
				setIconColor(iconColor + ' run-color')
				break;
			case "Completed" :
				setStatusLabel("Completed")
				setIconColor(iconColor + ' extracted-color')
				break;
			case "Failed" :
				setStatusLabel("Failed")
				setIconColor(iconColor + ' fail-color')
				break;
			case "Pending" :
				setStatusLabel("Pending")
				setIconColor(iconColor + ' pending-color')
				break;
			case "Cancelled":
				setStatusLabel("Canceled")
				setIconColor(iconColor + ' cancel-color')
				break;
		}

	}, [props.status])

	return (
		<div className="App-body-right-tab-status-table-status-label-container">
			<div className={iconColor}>
        	</div>
            <div className="App-body-right-tab-agent-content-status-overview">
          		{statusLabel}
        	</div>
		</div>
		)
}

export default JobStatusLabel