import '../App.css';
import DashboardBodyOverview from './dashboardbodyoverview'
import DashboardBodyContents from './dashboardbodycontents'
import React, { useState, useEffect } from 'react';


function DashboardBody(props) {

  const [agentStatus, setAgentStatus] = useState({})
  const [jobStatus, setJobStatus] = useState({})
  const [documentCount, setDocumentCount] = useState('')
  const [storeCount, setStoreCount] = useState('')
 
  function setOverview(agentStatus, jobStatus, documentCount, storeCount) {
      setAgentStatus(agentStatus)
      setJobStatus(jobStatus)
      setDocumentCount(documentCount)
      setStoreCount(storeCount)
  }

  function updateRefreshedTime(lastRefreshTime) {
      props.updateRefreshedTime(lastRefreshTime)
  }

  return (
    <div className="App-body-right-tab-dashboard-body">
      <DashboardBodyOverview agentStatus={agentStatus} jobStatus={jobStatus} documentCount={documentCount} storeCount={storeCount}/>
      <DashboardBodyContents shouldRefresh={props.shouldRefresh} updateRefreshedTime={updateRefreshedTime} handleShowSpinner={props.handleShowSpinner} setOverview={setOverview}/>
    </div>
  );
}

export default DashboardBody;
