import '../css/statusbodycontents.css';
import StatusBodyContainer from './statusbodycontainer'

function StatusBodyContents(props) {

  return (
      <div className="App-body-right-tab-status-content-container">
        <div className="App-body-right-tab-status-content-filter">
          
        </div>
        <div className="App-body-right-tab-status-content-contents">
            <StatusBodyContainer shouldRefresh={props.shouldRefresh} handleShowSpinner={props.handleShowSpinner} />
        </div>
      </div>
  );
}

export default StatusBodyContents;
