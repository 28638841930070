import React, { useState, useEffect } from 'react';
import ProgressIcon from './yearprogressicon'

function MigrationProgressIcon(props) {

  const [iconStatus, setIconStatus] = useState("")

  useEffect(() => {
    if ( !props.currentState.startsWith("migration_")){
      setIconStatus("Disabled")
    } else {
      if (props.currentState == "migration_start") {
        setIconStatus("Start")
      } else if ( props.currentState == "migration_run") {
        setIconStatus("Running")
      } else if ( props.currentState == "migration_fail") {
        setIconStatus("Failed")
      } else {
        setIconStatus("Completed")
      }
    }
  }, [props.currentState])

  function handleOnClick() {
    console.log("Icon clicked")
    props.onClick(props.job_data)
  }

  return(
      <ProgressIcon currentState={iconStatus} onClick={handleOnClick} />
    );
}

export default MigrationProgressIcon