import '../css/yearprogressbar.css';
import React, { useState, useEffect } from 'react';


function MetaYearProgressBar(props) {

	let progressBarCss = {
						"height" : "3px",
					  	"width" : "100%",
					  	"display": "flex",
					  	"backgroundColor" : "#D4D5D6",
					  	"boxSizing" : "border-box",
					  	"border": "1px solid #D4D5D6"
					}

	const [progressBarStyle, setProgressBarStyle] = useState(progressBarCss);

	useEffect(() => {
		let progressPercentage = props.progress / 100
		let newProgressBarStyle = Object.assign({}, progressBarCss);
		if (props.currentState != "") {	
			if (props.currentState == "metadata_run") {
				newProgressBarStyle['width'] = "40%"
				newProgressBarStyle['backgroundColor'] = "#0091FF"
				newProgressBarStyle['border'] = "1px solid #0091FF"
				setProgressBarStyle(newProgressBarStyle)
			} else if ( props.currentState != "metadata_start" && props.currentState != "metadata_fail") {
				newProgressBarStyle['width'] = "100%"
				newProgressBarStyle['backgroundColor'] = "#60D156"
				newProgressBarStyle['border'] = "1px solid #60D156"
				setProgressBarStyle(newProgressBarStyle)
			} 
		}
	} ,[props.currentState])

	return(
		<div className="year-progress-bar-container">
			<div class="year-progress-bar-envelope"> 
				<div style={progressBarStyle}></div>
			</div>
		</div>
	);

}

export default MetaYearProgressBar