import '../css/agent.css';
import AgentTitle from './agenttitle'
import AgentBody from './agentbody'
import React, { useState, useEffect } from 'react';


function Agent() {

  const [showSpinner, setShowSpinner] = useState(false)

  const [shouldRefresh, setShouldRefresh] = useState(false)

  function setShowRefreshSpinner(showSpinner) {
    setShowSpinner(showSpinner)
    setShouldRefresh(false)
  }

  function handleDashboardRefresh() {
    setShouldRefresh(true)
    setShowSpinner(true)
  }

  return (
    <div className="App-body-right-tab-agent-container">
      <AgentTitle showSpinner={showSpinner} handleDashboardRefresh={handleDashboardRefresh} />
      <AgentBody  shouldRefresh={shouldRefresh} handleShowSpinner={setShowRefreshSpinner} />
    </div>
  );
}

export default Agent;
