import './App.css';
import Title from './components/title'
import Body from './components/body'
import Login from './login'
import React, { useState, useEffect } from 'react'

function App() {

  const [showLogin, setShowLogin] = useState(true)

  useEffect(() => {
    var session_key = localStorage.getItem('drm_session_key')
    var session_apikey = localStorage.getItem('drm_session_apikey')
    var last_active_time = localStorage.getItem('last_active_time')
    var user_name = localStorage.getItem('user_name')
    var user_id = localStorage.getItem('user_id')
    var role = localStorage.getItem('role')
    var is_admin = false
    if (role === "admin") {
      is_admin = true
    }
    var curr_time = Date.now()
    if ( last_active_time !== null && session_apikey !== "" ) {
      var idle_time = curr_time - last_active_time
      var idle_time_mins = idle_time / ( 60 * 1000)
      if( idle_time_mins < 15 )  {
        setShowLogin(false)
      } else {
        setShowLogin(true)
      }
    } else {
     setShowLogin(true)
    }
  }, [])

  function update_login ( login_status, apikey, key, user_name, user_id, role) {
    var is_admin = false
    if( role === "admin") {
      is_admin = true
    }
    if (login_status === false) {
      localStorage.setItem('drm_session_apikey', apikey)
      localStorage.setItem('drm_session_key', key)
      localStorage.setItem('last_active_time', JSON.stringify(Date.now()))
      localStorage.setItem('user_id', user_id)
      localStorage.setItem('role', role)
      user_name = titleCase(user_name)
      localStorage.setItem('user_name', user_name)
      setShowLogin(false)
    } else {
      setShowLogin(true)
    }
  }

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
       // You do not need to check if i is larger than splitStr length, as your for does that for you
       // Assign it back to the array
       splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
  }

  function handleLogout() {
    localStorage.setItem('drm_session_apikey', '')
    localStorage.setItem('drm_session_key', '')
    localStorage.setItem('last_active_time', JSON.stringify(Date.now()))
    localStorage.setItem('user_id', '')
    localStorage.setItem('role', '')
    localStorage.setItem('user_name', '')
    setShowLogin(true)
  }

  return (
    <div className="App">
    { showLogin ? 
        <Login update_login={update_login}/>
      :
        <div className="App-body-container">
          <Title handleLogout={handleLogout}/>
          <Body/>
        </div>
    }
    </div>
  );
}

export default App;
