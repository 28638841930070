import React from 'react';
import '../css/logtabletitle.css'


function LogTableTitle(props) {

	return(
			<div className="App-body-right-tab-job-details-modal-body-log-table-header">
				<div className="App-body-right-tab-job-details-modal-body-log-table-title-content" id="App-body-right-tab-job-details-modal-title-audit-id">
          			<div className="App-body-right-tab-job-details-modal-body-log-table-title-content-detail">
          				Audit Id
          			</div>
          		</div>
          		<div className="App-body-right-tab-job-details-modal-body-log-table-title-content" id="App-body-right-tab-job-details-modal-title-status">
          			<div className="App-body-right-tab-job-details-modal-body-log-table-title-content-detail">
          				Status
          			</div>
          		</div>
          		<div className="App-body-right-tab-job-details-modal-body-log-table-title-content" id="App-body-right-tab-job-details-modal-title-message">
          			<div className="App-body-right-tab-job-details-modal-body-log-table-title-content-detail">
          				Message
          			</div>
          		</div>
          		<div className="App-body-right-tab-job-details-modal-body-log-table-title-content" id="App-body-right-tab-job-details-modal-title-triggered-time">
          			<div className="App-body-right-tab-job-details-modal-body-log-table-title-content-detail">
          				Triggered Time
          			</div>
          		</div>
			</div>
		)

}

export default LogTableTitle