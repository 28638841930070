import '../css/status.css';
import StatusTitle from './statustitle'
import StatusBody from './statusbody'
import React, { useState, useEffect } from 'react';


function Status(props) {

  const [showSpinner, setShowSpinner] = useState(false)

  const [shouldRefresh, setShouldRefresh] = useState(false)

  function setShowRefreshSpinner(showSpinner) {
    setShowSpinner(showSpinner)
    setShouldRefresh(false)
  }

  function handleDashboardRefresh() {
    setShouldRefresh(true)
    setShowSpinner(true)
  }

  return (
    <div className="App-body-right-tab-status-container">
      <StatusTitle showSpinner={showSpinner} handleDashboardRefresh={handleDashboardRefresh} />
      <StatusBody shouldRefresh={shouldRefresh} handleShowSpinner={setShowRefreshSpinner} />
    </div>
  );
}

export default Status;
