import StatusBodyTitle from './statusbodytitle'
import StatusBodyStatusTable from './statusbodystatustable'

function StatusBodyContainer(props) {

  return (
      <div className="App-body-right-tab-status-body-container">
        <StatusBodyTitle />
        <StatusBodyStatusTable shouldRefresh={props.shouldRefresh} handleShowSpinner={props.handleShowSpinner} />
      </div>
  );
}

export default StatusBodyContainer;
