import '../css/agentbodycontenttable.css';
import React, { useState, useEffect } from 'react';
import AgentStatus from './agentstatus'
import Spinner from './spinner'


function AgentBodyContentTable(props) {

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [agents, setAgents] = useState([])
  const [showLoader, setShowLoader] = useState(true)

  useEffect(() => {
    var apikey = localStorage.getItem('drm_session_apikey')
    fetch( BASE_URL + '/v1/agents/all', {
      method : 'GET',
      headers : {
        'x-api-key' : apikey
      }
    })
    .then(res => res.json())
    .then((data) => {
        setAgents(data['message'])
        setShowLoader(false)
    })
    .catch(console.log)
  }, [BASE_URL]);

  useEffect(() => {
    var apikey = localStorage.getItem('drm_session_apikey')
    if ( props.shouldRefresh) {
      setShowLoader(true)
      props.handleShowSpinner(true)
      fetch( BASE_URL + '/v1/agents/all', {
        method : 'GET',
        headers : {
          'x-api-key' : apikey
        }
      })
      .then(res => res.json())
      .then((data) => {
        setAgents(data['message'])
        setShowLoader(false)
        props.handleShowSpinner(false)
    })
    .catch(console.log)
    }
  }, [props.shouldRefresh])

  return (
    <div className="App-body-right-tab-agent-content-table-container">
    { showLoader ? <Spinner /> :
      <table className="App-body-right-tab-agent-content-table">
        <thead>
            <tr id="App-body-right-tab-agent-content-table-header">
              <th id="App-body-right-tab-agent-content-table-data-agent-name" scope="col">Agent Alias</th>
              <th id="App-body-right-tab-agent-content-table-data-agent-display-name" scope="col">Agent Name</th>
              <th id="App-body-right-tab-agent-content-table-data-agent-tenant-name"scope="col">Group Name</th>
              <th id="App-body-right-tab-agent-content-table-data-agent-dealership-code" scope="col">Dealership Name</th>
              <th id="App-body-right-tab-agent-content-table-data-dealer-id-header" scope="col">Dealer ID</th>
              <th id="App-body-right-tab-agent-content-table-data-agent-type" scope="col">DMS Type</th>
              <th id="App-body-right-tab-agent-content-table-data-agent-status" scope="col">Status</th>
            </tr>
        </thead>
        <tbody>
          {agents.map((row, index) => (
              <tr key={index}>
                <td>{row.agent_name}</td>
                <td>{row.name}</td>
                <td>{row.tenant_name}</td>
                <td>{row.dealership_code}</td>
                <td id="App-body-right-tab-agent-content-table-data-dealer-id">{row.dealer_id}</td>
                <td>{row.type}</td>
                <td><AgentStatus status={row.status} /></td>
              </tr>
            ))}
        </tbody>
      </table>
    }
    </div>
  );
}

export default AgentBodyContentTable;
