import '../css/dealerstatusdetails.css'
import DealerStatusModules from './dealerstatusmodules'
import DealerStatusModulesData from './dealerstatusmodulesdata'
import { useState, useEffect } from 'react';
import Spinner from './spinner'


function DealerStatusDetails(props) {

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [subModules, setSubModules] = useState([])

  const [showLoader, setShowLoader] = useState(true)

  const [selectedModule, setSelectedModule] = useState('')

  const [showError, setShowError] = useState(false)

  const [selectedSubModule, setSelectedSubModule] = useState('')

  const [extractionData, setExtractionData] = useState([])

  const [runningJobsCount, setRunningJobsCount] = useState('')


  function handleModuleSelection(module, subModules) {
    setSubModules(subModules)
    setExtractionData([])
    setShowLoader(true)
    setSelectedModule(module)
    handleSubModuleSelection(module, subModules[0])
  }

  function handleSubModuleSelection(module, subModule) {
    setShowLoader(true)
    setExtractionData([])
    setSelectedSubModule(subModule)
    var apikey = localStorage.getItem('drm_session_apikey')
    fetch(BASE_URL + '/v1/jobs/year?tenant_name=' + props.dealer_status.tenant_name + '&dealer_id=' + props.dealer_status.dealer_id 
      + '&module=' + module + '&sub_module=' + subModule, {
        method : 'GET',
        headers:{
                'x-api-key': apikey
            }
      }).then((res) => res.json())
      .then((data) => {
        setExtractionData(data['message']['data'])
        setRunningJobsCount(data['message']['metadata']['running_count'])
        setShowLoader(false)
      })
  }

  function showModuleError(showError) {
    console.log("show Error change")
    console.log(showError)
    setShowError(showError)
  }

  function showLoading() {
    setShowLoader(true)
  }


  return (
      <div className="App-body-right-tab-status-accordion-details">
        <div className="App-body-right-tab-status-accordion-details-left-space">
        </div>
        <DealerStatusModules showError={showError} showLoader={showLoading} setShowError={showModuleError} dms_type={props.dealer_status.type} module_data={props.dealer_status.module_data} setSubModules={handleModuleSelection} />
        {showLoader ? 
            <Spinner />
          :
            <DealerStatusModulesData 
              extractionData={extractionData} 
              subModules={subModules}
              subModule={selectedSubModule}
              module={selectedModule}
              showError = {showModuleError}
              runningJobsCount = {runningJobsCount}
              dealer_status={props.dealer_status}
              handleSubModuleSelection={handleSubModuleSelection} />
        }
      </div>
  );
}

export default DealerStatusDetails;
