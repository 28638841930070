import '../css/agent.css';
import AgentBodyContents from './agentbodycontents'

function AgentBody(props) {

  return (
    <div className="App-body-right-tab-agent-body">
      <AgentBodyContents shouldRefresh={props.shouldRefresh} handleShowSpinner={props.handleShowSpinner}/>
    </div>
  );
}

export default AgentBody;
