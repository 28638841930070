import React, { useState, useEffect} from 'react';
import '../css/additionalinsights.css';
import JobInsights from './jobInsights'
import MigrationModal from './migrationModal'
import MigrateModal from './migrateModal'

function AdditionalInsights(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [showInsight, setShowInsight] = useState(false)
	const [showMigrationPopup, setShowMigrationPopup] = useState(false)
	const [migrationInfo, setMigrationInfo] = useState('')
	const [showMigrateModal, setShowMigrateModal] = useState(false)
	const [showMigrationModalLoader, setShowMigrationModalLoader] = useState(false)

	function handleInsightSelect() {
		setShowInsight(true)
	}

	function handleInsightClose() {
		setShowInsight(false)
		props.handleAddInfoClose()
	}


  	function handleMigrateClose() {
    	setShowMigrationPopup(false)
    	props.refreshData()
    	props.handleAddInfoClose()
  	}

  function handleMigrateModal() {
  	setShowMigrateModal(true)
  }

  function handleMigrateModalClose() {
  	setShowMigrateModal(false)
  	props.handleAddInfoClose()
  }

  function handleMigrateModelDone() {
  	setShowMigrationModalLoader(true)
  	handleMigrate()
  	setShowMigrateModal(false)
  }

  function handleMigrate() {
    setShowMigrationPopup(true)
    var apikey = localStorage.getItem('drm_session_apikey')
    fetch( BASE_URL + '/v1/archive', {
        method : 'POST',
        headers : {
          'x-api-key' : apikey,
          'Accept':'application/json',
          'Content-Type':'application/json',
        },
        body : JSON.stringify({
              tenant_name: props.dealer_status.tenant_name,
              dealer_id: props.dealer_status.dealer_id
        })
    })
    .then(res => res.json())
    .then((data) => {
        setMigrationInfo(data['message'])
        setShowMigrationModalLoader(false)
    })
    .catch(console.log)
  }


	return (
			<div class="App-body-right-tab-status-table-status-add-insights">
				<div class="App-body-right-tab-status-table-status-add-insights-content-close">
			    	<div class="App-body-right-tab-status-table-status-add-insights-content-label-close">
			    		<div class="close" onClick={props.handleAddInfoClose}>&times;</div>
			    	</div>
			    </div>
			    <div class="App-body-right-tab-status-table-status-add-insights-content" onClick={handleInsightSelect}>
			    	<div class="App-body-right-tab-status-table-status-add-insights-content-label">
			    		Insights
			    	</div>
			    </div>
			    <div class="App-body-right-tab-status-table-status-add-insights-content" onClick={handleMigrateModal}>
			    	<div class="App-body-right-tab-status-table-status-add-insights-content-label">
			    		Mark Complete
			    	</div>
			    </div>
			    {showInsight && <JobInsights handleInsightClose={handleInsightClose} dealer_status={props.dealer_status} />}
			    {showMigrationPopup && <MigrationModal message={migrationInfo} handleErrorClose={handleMigrateClose} />}
			    {showMigrateModal && <MigrateModal showLoader={showMigrationModalLoader} handleClose={handleMigrateModalClose} handleSuccess={handleMigrateModelDone}/> }
			</div>
		)
}

export default AdditionalInsights