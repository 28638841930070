import React, { useState, useEffect} from 'react';
import '../css/joblogs.css'
import Spinner from './spinner'
import JobLogRow from './joblogrow'
import JobDetails from './jobdetails'


function JobLogs(props) {

	const [showSpinner, setShowSpinner] = useState(true)
	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const [jobs, setJobs] = useState([])
	const [selectedJob, setSelectedJob] = useState({})
	const [showJobDetail, setShowJobDetail] = useState(false)


	useEffect(() => {
		refreshJob()
	},[])

	function refreshJob() {
		var apikey = localStorage.getItem('drm_session_apikey')
		var user_key =localStorage.getItem('drm_session_key')

		fetch(BASE_URL+"/v1/jobs/filter?tenant_name=" + props.tenant_name + 
				"&dealer_id=" + props.dealer_id + "&status=Running&module=&user=", {
			method : 'GET',
		    headers:{
				'x-api-key': apikey,
				'user_key' : user_key

			}
		}).then(res => res.json())
		   .then((data) => {
		   		setShowSpinner(false)
		   		setJobs(data['message'])
		    })
		  	.catch(console.log)
	}

	function handleJobClose() {
		setShowJobDetail(false)
		setSelectedJob({})
	}

	function handleJobDetail(job) {
		setShowJobDetail(true)
		setSelectedJob(job)
	}

	return(
			<div className="App-body-right-tab-running-jobs-modal-body-job-logs-table">
				<div className="App-body-right-tab-running-jobs-logs-table-header">
					<div className="App-body-right-tab-running-jobs-logs-table-header-name" id="App-body-right-tab-running-jobs-logs-table-module">
						<div className="App-body-right-tab-running-jobs-logs-table-header-content">
							Module
						</div>
					</div>
					<div className="App-body-right-tab-running-jobs-logs-table-header-name" id="App-body-right-tab-running-jobs-logs-table-agent">
						<div className="App-body-right-tab-running-jobs-logs-table-header-content">
							Agent
						</div>
					</div>
					<div className="App-body-right-tab-running-jobs-logs-table-header-name" id="App-body-right-tab-running-jobs-logs-table-year">
						<div className="App-body-right-tab-running-jobs-logs-table-header-content">
							Year
						</div>
					</div>
					<div className="App-body-right-tab-running-jobs-logs-table-header-name" id="App-body-right-tab-running-jobs-logs-table-last-updated">
						<div className="App-body-right-tab-running-jobs-logs-table-header-content">
							Last Updated
						</div>
					</div>
					<div className="App-body-right-tab-running-jobs-logs-table-header-name" id="App-body-right-tab-running-jobs-logs-table-time-taken">
						<div className="App-body-right-tab-running-jobs-logs-table-header-content">
							Time Taken (secs)
						</div>
					</div>
					<div className="App-body-right-tab-running-jobs-logs-table-header-name" id="App-body-right-tab-running-jobs-logs-table-triggered-by">
						<div className="App-body-right-tab-running-jobs-logs-table-header-content">
							Triggered By
						</div>
					</div>
					<div className="App-body-right-tab-running-jobs-logs-table-header-name" id="App-body-right-tab-running-jobs-logs-table-status">
						<div className="App-body-right-tab-running-jobs-logs-table-header-content">
							Status
						</div>
					</div>
					<div className="App-body-right-tab-running-jobs-logs-table-header-name" id="App-body-right-tab-running-jobs-logs-table-logs">
						<div className="App-body-right-tab-running-jobs-logs-table-header-content">
							Logs
						</div>
					</div>
					<div className="App-body-right-tab-running-jobs-logs-table-header-name" id="App-body-right-tab-running-jobs-logs-table-cancel">
						<div className="App-body-right-tab-running-jobs-logs-table-header-content">
							
						</div>
					</div>
				</div>
				<div className="App-body-right-tab-running-jobs-logs-table-body">
					{showSpinner ?
					 	<Spinner /> 
					 : 
					 	<div className="App-body-right-tab-running-jobs-logs-table-body-container">
					 		{jobs.map((job, index) => (
					 			<JobLogRow job={job} key={index} refreshJob={refreshJob} handleJobDetail={handleJobDetail} />
					 		))}
					 	</div>
					 }
				</div>
				{
					showJobDetail &&
						<JobDetails job={selectedJob} handleJobClose={handleJobClose} />
				}
			</div>
		)
}

export default JobLogs