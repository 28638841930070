import '../css/yearprogressicon.css';
import React, { useState, useEffect } from 'react';


function ProgressIcon(props) {

  const [className, setClassName] = useState("App-body-right-tab-year-progress-icon")
  const [progIconName, setProgIconName] = useState("progress-icon")

  useEffect(() => {
    console.log(props.currentState)
    if (props.currentState == "Completed") {
      let selectedClassName = className + " icon-completed"
      setClassName(selectedClassName)
    } else if ( props.currentState == "Running") {
      let selectedClassName = className + " icon-running"
      setClassName(selectedClassName)
    } else if ( props.currentState == "Failed") {
      let selectedClassName = className + " icon-fail"
      setClassName(selectedClassName)
    } else if ( props.currentState == "Start") {
      let selectedClassName = className + " icon-start"
      setClassName(selectedClassName)
    } else if ( props.currentState == "Disabled") {
      let selectedClassName = className + ' icon-disabled'
      setClassName(selectedClassName)
      setProgIconName("progress-icon-disabled")
    }
  }, [props.currentState])

  return(
      <div className={className} onClick={props.onClick}>
        <div class={progIconName}>
        {props.currentState == "Completed" ? 
          <i class="icon-tick1"></i>
          : props.currentState == "Running" ?
            <i class="icon-frequency"/>
            : props.currentState == "Failed" ?
              <i class="icon-cross" />
              : props.currentState == "Disabled" ?
                <i class="fa fa-caret-right" aria-hidden="true"></i>
                : <i class="icon-caret-right"/>
        }
        </div>
      </div>
    );
}

export default ProgressIcon