import '../css/dealerstatusmodules.css'
import ModuleProgress from './moduleprogressbar'
import { useState, useEffect } from 'react';
import StatusModulesNav from './statusmodulesnav'


function handleClick(module) {
    console.log(module)
    console.log("Div accounting clicked")
  }

function DealerStatusModules(props) {

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [accountModuleCss, setAccountModuleCss] = useState("App-body-right-tab-status-accordion-details-module accordion-selected")
  const [partModuleCss, setPartModuleCss] = useState("App-body-right-tab-status-accordion-details-module")
  const [salesModuleCss, setSalesModuleCss] = useState("App-body-right-tab-status-accordion-details-module")
  const [serviceModuleCss, setServiceModuleCss] = useState("App-body-right-tab-status-accordion-details-module")
  const [payrollModuleCss, setPayrollModuleCss] = useState("App-body-right-tab-status-accordion-details-module")
  const [accountProgress, setAccountProgress] = useState(0)
  const [partProgress, setPartProgress] = useState(0)
  const [salesProgress, setSalesProgress] = useState(0)
  const [serviceProgress, setServiceProgress] = useState(0)
  const [payrollProgress, setPayrollProgress] = useState(0)
  const [selectedModule, setSelectedModule] = useState('')


  function fetchSubModules(module) {
    var apikey = localStorage.getItem('drm_session_apikey')
    props.showLoader()
    fetch(BASE_URL+'/v1/modules/submodule?module='+ module.toLowerCase()+'&dms_type=' + props.dms_type, {
            method : 'GET',
            headers:{
              'x-api-key': apikey
            }
          }
      ).then(res => res.json())
       .then((data) => {
          props.setSubModules(module, data['message'])
      })
  }

  function getModulePercentages() {
    for (var module_data in props.module_data ) {
      var current_module_data = props.module_data[module_data]
      var current_module = current_module_data['module']
      var extracted_count = current_module_data['actual_count']
      var estimated_count = current_module_data['estimated_count']
      var percentage = (extracted_count/estimated_count) * 100
      if(percentage > 100) {
        percentage = 100
      }
      percentage = Math.round(percentage)
      switch ( current_module ) {
        case "accounting" :
          setAccountProgress(percentage)
          break;
        case "parts" :
          setPartProgress(percentage)
          break;
        case "sales" :
          setSalesProgress(percentage)
          break;
        case "service" :
          setServiceProgress(percentage)
          break;
        case "payroll" :
          setServiceProgress(percentage)
          break;
      } 
    }
  }

  function handleModuleClick(module) {
    fetchSubModules(module)
    setSelectedModule(module)
    props.setShowError(false)
    if (module == "accounting") {
      setAccountModuleCss("App-body-right-tab-status-accordion-details-module accordion-selected")
      setPartModuleCss("App-body-right-tab-status-accordion-details-module")
      setSalesModuleCss("App-body-right-tab-status-accordion-details-module")
      setServiceModuleCss("App-body-right-tab-status-accordion-details-module")
      setPayrollModuleCss("App-body-right-tab-status-accordion-details-module")
    } else if ( module == "parts") {
      setAccountModuleCss("App-body-right-tab-status-accordion-details-module")
      setPartModuleCss("App-body-right-tab-status-accordion-details-module accordion-selected")
      setSalesModuleCss("App-body-right-tab-status-accordion-details-module")
      setServiceModuleCss("App-body-right-tab-status-accordion-details-module")
      setPayrollModuleCss("App-body-right-tab-status-accordion-details-module")
    } else if ( module == "sales") {
      setAccountModuleCss("App-body-right-tab-status-accordion-details-module")
      setPartModuleCss("App-body-right-tab-status-accordion-details-module")
      setSalesModuleCss("App-body-right-tab-status-accordion-details-module accordion-selected")
      setServiceModuleCss("App-body-right-tab-status-accordion-details-module")
      setPayrollModuleCss("App-body-right-tab-status-accordion-details-module")
    } else if ( module == "service") {
      setAccountModuleCss("App-body-right-tab-status-accordion-details-module")
      setPartModuleCss("App-body-right-tab-status-accordion-details-module")
      setSalesModuleCss("App-body-right-tab-status-accordion-details-module")
      setServiceModuleCss("App-body-right-tab-status-accordion-details-module accordion-selected")
      setPayrollModuleCss("App-body-right-tab-status-accordion-details-module")
    } else {
      setAccountModuleCss("App-body-right-tab-status-accordion-details-module")
      setPartModuleCss("App-body-right-tab-status-accordion-details-module")
      setSalesModuleCss("App-body-right-tab-status-accordion-details-module")
      setServiceModuleCss("App-body-right-tab-status-accordion-details-module")
      setPayrollModuleCss("App-body-right-tab-status-accordion-details-module accordion-selected")
    }

  }

  useEffect(() => {
    getModulePercentages()
    setAccountModuleCss("App-body-right-tab-status-accordion-details-module accordion-selected")
    fetchSubModules("accounting")
    setSelectedModule("accounting")
  }, [])

  return (
        <div className="App-body-right-tab-status-accordion-details-modules">
          <StatusModulesNav selectedModule={selectedModule} showError={props.showError} css={accountModuleCss} moduleValue="accounting" moduleName="Accounting" progress={accountProgress} handleModuleClick={handleModuleClick}/>
          <StatusModulesNav selectedModule={selectedModule} showError={props.showError} css={partModuleCss} moduleValue="parts" moduleName="Parts" progress={partProgress} handleModuleClick={handleModuleClick} />
          <StatusModulesNav selectedModule={selectedModule} showError={props.showError} css={salesModuleCss} moduleValue="sales" moduleName="Sales" progress={salesProgress} handleModuleClick={handleModuleClick}/>
          <StatusModulesNav selectedModule={selectedModule} showError={props.showError} css={serviceModuleCss} moduleValue="service" moduleName="Service" progress={serviceProgress} handleModuleClick={handleModuleClick}/>
          <StatusModulesNav selectedModule={selectedModule} showError={props.showError} css={payrollModuleCss} moduleValue="payroll" moduleName="Payroll" progress={payrollProgress} handleModuleClick={handleModuleClick}/>
        </div>
  );
}

export default DealerStatusModules;
