import logo from '../tekion-logo.png';
import '../App.css';

function Title(props) {

  function handleLogout() {
    props.handleLogout()
  }

  return (
    <div className="App-title-block">
        <div className="App-title-block-9-icon">
          <div className="icon-nine-dots-menu">
          </div>
        </div>
        <div className="App-title-block-title">
          <img src={logo} alt="" class="App-title-block-title-logo"/>
        </div>
        <div className="App-title-block-logout" onClick={handleLogout}>
            Logout
        </div>
    </div>
  );
}

export default Title;
