import React, { useState, useEffect } from 'react';
import '../css/countvalidation.css'

function SizeValidation(props) {

	return(
			<div className="App-body-count-validation-modal">
				<div className="App-body-size-validation-modal-content">
					<div className="App-body-count-validation-modal-header">
						<div className="App-body-count-validation-modal-header-content">Size Validation Results</div>
						<div className="close" onClick={props.handleJobClose}>&times;</div>
					</div>
					<div className="App-body-count-validation-modal-body">
						{props.validationData.size_status ?
							<p class="size-validation-message">
	            					<i class="fa fa-check tekion-success-color" aria-hidden="true"></i> 
	            					All the documents are above the minimum size of 1KB. 
            				</p>
            			: 
            				<div>
	              					<p class="size-validation-message">
	              						<i class="fa fa-times tekion-fail-color" aria-hidden="true"></i>
										There are <i class="tekion-fail-color">{props.validationData.insights.low_size_count}</i> with lower size than 1 KB. We have listed the documents below
					            	</p>
					            	<div className="size_validation_table">
						                <div className="size_validation_table_header">
						                 	Documents						                  
						                </div>
						                <div className="size_validation_table_body">
						                	{props.validationData.insights.size_failures.map((document) => (
						                		<div className="size_validation_table_row">
						                			{document}
						                		</div>
						                	))}	      
						                </div>
						            </div>
						        </div>
						}
					</div>
				</div>
			</div>
		)
}

export default SizeValidation