import '../css/agentbodycontents.css';
import AgentBodyContentTable from './agentbodycontenttable'

function AgentBodyContents(props) {

  return (
      <div className="App-body-right-tab-agent-content-container">
        <div className="App-body-right-tab-agent-content-filter">
          
        </div>
        <div className="App-body-right-tab-agent-content-contents">
            <AgentBodyContentTable shouldRefresh={props.shouldRefresh} handleShowSpinner={props.handleShowSpinner} />
        </div>
      </div>
  );
}

export default AgentBodyContents;
