import React, { useState, useEffect } from 'react';
import '../css/logtablecontents.css'
import Spinner from './spinner'
import JobStatusLabel from './jobsstatuslabel'


function LogTableContents(props) {

	const [showSpinner, setShowSpinner] = useState(true)
	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const [logs, setLogs] = useState([])


	useEffect(() => {
		var apikey = localStorage.getItem('drm_session_apikey')
		fetch(BASE_URL+"/v1/audit/" + props.job_id, {
			method : 'GET',
		    headers:{
				'x-api-key': apikey
			}
		}).then(res => res.json())
		   .then((data) => {
		   		console.log(data['message'])
		   		setShowSpinner(false)
		    	setLogs(data['message'])
		    })
		  	.catch(console.log)
	},[props.job_id])


	return(
			<div className="App-body-right-tab-job-details-modal-body-log-table-body">
				{
					showSpinner ?
						<Spinner />
					:
					 logs.map((log, index) => (
					 	<div className="App-body-right-tab-job-details-modal-body-log-table-body-content" key={index}>
					 		<div className="App-body-right-tab-job-details-modal-body-log-table-content" id="App-body-right-tab-job-details-modal-content-audit-id">
          						<div className="App-body-right-tab-job-details-modal-body-log-table-content-detail">
					 				{log.audit_id}
					 			</div>
					 		</div>
					 		<div className="App-body-right-tab-job-details-modal-body-log-table-content" id="App-body-right-tab-job-details-modal-content-status">
          						<div className="App-body-right-tab-job-details-modal-body-log-table-content-detail">
					 				<JobStatusLabel status={log.status} />
					 			</div>
					 		</div>
					 		<div className="App-body-right-tab-job-details-modal-body-log-table-content" id="App-body-right-tab-job-details-modal-content-message">
          						<div className="App-body-right-tab-job-details-modal-body-log-table-content-detail">
					 				{log.message}
					 			</div>
					 		</div>
					 		<div className="App-body-right-tab-job-details-modal-body-log-table-content" id="App-body-right-tab-job-details-modal-content-triggered-time">
          						<div className="App-body-right-tab-job-details-modal-body-log-table-content-detail">
					 				{log.timestamp}
					 			</div>
					 		</div>
					 	</div>
					 ))

				}
			</div>
		)
}

export default LogTableContents