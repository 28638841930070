import React, { useState, useEffect} from 'react';
import '../css/dealerstatuslabel.css'

function DealerStatusLabel(props) {

	const [iconColor, setIconColor] = useState('App-body-right-tab-status-lable-icon')
	const [statusLabel, setStatusLabel] = useState('Running')

	useEffect(() => {
		switch(props.status) {
			case "Jobs Triggered":
			case "Validation Triggered":
				setStatusLabel('Running')
				setIconColor(iconColor + ' run-color')
				break;
			case "Validation Completed":
				setStatusLabel('Validated') 
				setIconColor(iconColor + ' validation-color')
				break;
			case "Extraction Completed" :
				setStatusLabel("Extracted")
				setIconColor(iconColor + ' extracted-color')
				break;
			case "Extraction Failed" :
			case "Validation Failed" :
			case "Jobs Failed" :
				setStatusLabel("Failed")
				setIconColor(iconColor + ' fail-color')
				break;
			case "Extraction Cancelled":
				setStatusLabel("Cancelled")
				setIconColor(iconColor + ' cancel-color')
				break;
		}

	}, [])

	return (
		<div className="App-body-right-tab-status-table-status-label-container">
			<div className={iconColor}>
        	</div>
            <div className="App-body-right-tab-agent-content-status-overview">
          		{statusLabel}
        	</div>
		</div>
		)
}

export default DealerStatusLabel