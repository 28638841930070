import '../css/dashboardbodyoverview.css';
import DashboardBodyOverviewContents from './dashboardoverviewcontents'

function DashboardBodyOverview(props) {

  return (
    <div className="App-body-right-tab-dashboard-overview-container">
        <div className="App-body-right-tab-dashboard-overview-title-container">
            <div className="App-body-right-tab-dashboard-overview-title">
                Overview
            </div>
        </div>
        <DashboardBodyOverviewContents storeCount={props.storeCount} agentStatus={props.agentStatus} jobStatus={props.jobStatus} documentCount={props.documentCount} />
    </div>
  );
}

export default DashboardBodyOverview;
