import '../css/dealerstatusmodulesdata.css'
import StatusBodyYearProgressContainer from './statusbodyyearprogresscontainer'
import AddJobModal from './addjobmodal'
import React, { useState, useEffect} from 'react';


function DealerStatusModulesData(props) {

  var [showAdd, setShowAdd] = useState(false)
  var [showLoader, setShowLoader] = useState(false)
  var [showError, setShowError] = useState(false)
  var [errorMsg, setErrorMsg] = useState('')

  var [value, setValue] = useState('')

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if(props.subModule != '') {
      setValue(props.subModule)
    }
  }, [props.subModule])

  function handleSubModuleChange(event) {
    var subModule = event.target.value
    props.handleSubModuleSelection(props.module, subModule)
  }

  function handleRefresh(module, subModule) {
    props.handleSubModuleSelection(module, subModule)
  }

  function handleClick() {
    console.log("add clicked")
    setShowAdd(true)
  }

  function handleAddClose() {
    setShowAdd(false)
  }

  function handleErrorClose() {
    setShowError(false)
    setShowAdd(false)
  }

  function handleSubmit(selectedYear) {
        setShowLoader(true)
        props.showError(false)
        var session_apikey = localStorage.getItem('drm_session_apikey')
        var session_key = localStorage.getItem('drm_session_key')
        var selectedYears = []
        selectedYears = selectedYears.concat(selectedYear)
        fetch(BASE_URL + '/v2/jobs/trigger', {
            method: 'POST',
            headers: {
              'Accept':'application/json',
              'Content-Type':'application/json',
              'x-api-key': session_apikey,
              'user_key' : session_key 
            },
            body : JSON.stringify({
              tenant_name: props.dealer_status.tenant_name,
              dealer_id: props.dealer_status.dealer_id,
              module: props.module.toLowerCase(),
              sub_module : props.subModule,
              dms_type : props.dealer_status.type,
              type : 'metadata',
              year : selectedYears,
              is_fresh_extract : true
            })
        }).then(res => {
            console.log("hello")
            console.log(res)
            if (res.status == "500") {
                console.log("assdas")
                console.log(showError)
                setShowError(true)
                res.json().then((data) => {
                  setErrorMsg(data['message']['error_message'])
                })
                setShowLoader(false)
            } else {
              return res.json()
            }
        })
        .then((data) => {
            if( data != undefined) {
              setShowAdd(false)
              handleRefresh(props.module, props.subModule)
              setShowLoader(false)
            }
        })
        .catch(err => {
            console.log(err)
            setShowAdd(true)
        })
    }

  return (
      <div className="App-body-right-tab-status-accordion-details-module-data-container">
        <div className="App-body-right-tab-status-accordion-details-module-data-title-container">
          <div className="App-body-right-tab-status-accordion-details-module-data-add-year">
              <div className="App-body-right-tab-status-accordion-details-module-data-add-year-button" onClick={handleClick}>
                Add Year
              </div>
              {showAdd ? 
                  <AddJobModal showError={showError} errorMsg={errorMsg} handleErrorClose={handleErrorClose} showLoader={showLoader} handleAddJob={handleSubmit} handleAddClose={handleAddClose} />
                : <div></div>}
          </div>
          <div className="App-body-right-tab-status-accordion-details-module-data-sub-module-container">
            <div className="App-body-right-tab-status-accordion-details-module-data-sub-module-filter-label">
              Module:
            </div>
            <select className="App-body-right-tab-status-accordion-details-module-data-sub-module-filter" value={value} onChange={handleSubModuleChange} name="submodules" id="submodules">
              {props.subModules.map((submodule, index) => (
                <option key={index} value={submodule}>{submodule}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="App-body-right-tab-status-accordion-details-module-data-content-container">
            <StatusBodyYearProgressContainer 
              extractionData={props.extractionData} 
              subModule={props.subModule}
              showError={props.showError}
              module={props.module}
              runningJobsCount={props.runningJobsCount}
              dealer_status={props.dealer_status}
              handleRefresh={handleRefresh} />
        </div>
      </div>
  );
}

export default DealerStatusModulesData;
