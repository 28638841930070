import '../css/dashboardbodycontenttable.css';
import React, { useState, useEffect } from 'react';
import DashboardBodyContentProgressBar from './dashboardbodycontentprogressbar'
import Spinner from './spinner'


function DashboardBodyContentTable(props) {

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [extractionStatus, setExtractionStatus] = useState([])

  const [showLoader, setShowLoader] = useState(true)

  const [showRefresh, setShowRefresh] = useState(false)


  useEffect(() => {
    var apikey = localStorage.getItem('drm_session_apikey')
      fetch( BASE_URL + '/v1/dashboard', {
        method : 'GET',
        headers : {
          'x-api-key' : apikey
        }
      })
      .then(res => res.json())
      .then((data) => {
          setExtractionStatus(data['message']['extraction_status'])
          if(data['message']['status'] == "Refreshing") {
            setShowLoader(true)
            props.updateRefreshedTime(data['message']['updated_time'])
          } else {
            setShowLoader(false)
            setShowRefresh(false)
            props.updateRefreshedTime(data['message']['updated_time'])
          }
          props.setOverview(data['message']['agent_status'], data['message']['job_status'], data['message']['doc_count'], data['message']['dealer_count'])
      })
      .catch(console.log)
    
  }, [BASE_URL]);


  useEffect(() => {
    var apikey = localStorage.getItem('drm_session_apikey')
    if ( props.shouldRefresh) {
      setShowLoader(true)
      props.handleShowSpinner(true)
      fetch( BASE_URL + '/v1/dashboard/refresh', {
        method : 'POST',
        headers : {
          'x-api-key' : apikey
        }
      })
      .then(res => res.json())
      .then((data) => {
        if(data['message']['status'] == "Refreshing") {
          console.log(data['message']['status'])
          setShowRefresh(true)
          setShowLoader(true)
          props.updateRefreshedTime(data['message']['updated_time'])
        }
        props.handleShowSpinner(false)
    })
    .catch(console.log)
    }
  }, [props.shouldRefresh])

  useEffect(() => {
    if (showRefresh) {
      setShowLoader(true)
    }
  }, [showRefresh])

  return (
    <div className="App-body-right-tab-dashboard-content-table-container">
      {showLoader ? 
        <Spinner /> 
        :
          <table className="App-body-right-tab-dashboard-content-table">
            <thead>
                <tr id="App-body-right-tab-dashboard-content-table-header">
                  <th id="App-body-right-tab-dashboard-content-table-data-tenant-name" scope="col">Group Name</th>
                  <th id="App-body-right-tab-dashboard-content-table-data-dealer-name" scope="col">Dealership Name</th>
                  <th id="App-body-right-tab-dashboard-content-table-data-dealer-id-header" scope="col">Dealer ID</th>
                  <th id="App-body-right-tab-dashboard-content-table-data-dms-type" scope="col">DMS Type</th>
                  <th id="App-body-right-tab-dashboard-content-table-data-go-live" scope="col">Go Live Date</th>
                  <th id="App-body-right-tab-dashboard-content-table-data-progress-bar" scope="col">Progress</th>
                </tr>
            </thead>
            <tbody>
              {extractionStatus.map((row, index) => (
                  <tr key={index}>
                    <td>{row.tenant_display_name}</td>
                    <td>{row.dealer_display_name}</td>
                    <td id="App-body-right-tab-dashboard-content-table-data-dealer-id">{row.dealer_id}</td>
                    <td>{row.type}</td>
                    <td>{row.launch_date}</td>
                    <td><DashboardBodyContentProgressBar extractionData={row} /></td>
                  </tr>
                ))}
            </tbody>
          </table>
      }
    </div>
  );
}

export default DashboardBodyContentTable;
