import '../App.css';
import React, { useState } from 'react';
import LeftTab from './lefttab'
import RightTab from './righttab'

function Body() {

  const [selectedTab, setSelectedTab] = useState("Dashboard")

  function handleTabSelection(tabName) {
    setSelectedTab(tabName);
  }

  return (
    <div className="App-body-block">
      <div className="App-body-block-upper-title-tab">
      </div>
      <div className="App-body-block-content">
        <div className="App-body-block-left-tab">
          <LeftTab handleTabSelection={handleTabSelection}/>
        </div>
        <div className="App-body-block-right-tab">
          <RightTab selectedTab={selectedTab}/>
        </div>
      </div>
    </div>
  );
}

export default Body;
