import React, { useState, useEffect } from 'react';
import '../css/validationresult.css'
import Spinner from './spinner'
import CountValidation from './countvalidation'
import SizeValidation from './sizevalidation'


function ValidationResult(props) {

	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const [validation, setValidation] = useState([])

	const [showSpinner, setShowSpinner] = useState(true)

	const [showSizeValidation, setShowSizeValidation] = useState(false)

	const [showCountValidation, setShowCountValidation] = useState(false)

	useEffect(() => {
		var apikey = localStorage.getItem('drm_session_apikey')
		fetch( BASE_URL + "/v1/validator/year?tenant_name=" + props.job.tenant_name 
				+ "&dealer_id=" + props.job.dealer_id + "&module=" + props.job.module
				+ "&sub_module=" + props.job.sub_module + "&year=" + props.job.year[0], {
			method : 'GET',
			headers : {
				'x-api-key' : apikey
			}
		}).then(res => res.json())
		.then((data) => {
			setValidation(data['message'][0])
			setShowSpinner(false)
		})
	}, [])

	function handleSizeValidation() {
		console.log("size validation clicked")
		setShowSizeValidation(true)
	}

	function handleCountValidation() {
		setShowCountValidation(true)
	}

	function handleCountValidationClose() {
		setShowCountValidation(false)
	}

	function handleSizeValidationClose() {
		setShowSizeValidation(false)
	}

	return(
			<div className="App-body-right-tab-job-details-modal-body-validation-result-container">
    			<div className="App-body-right-tab-job-details-modal-body-validation-result-header">
    				<div className="App-body-right-tab-job-details-modal-body-validation-result-header-content">
    					Validation Results
    				</div>
    			</div>
    			<div className="App-body-right-tab-job-details-modal-body-validation-result-content">
    				<div className="App-body-right-tab-job-details-modal-body-validation-result-table-container">
    					<div className="App-body-right-tab-job-details-modal-body-validation-result-table-header">
							<div className="App-body-right-tab-job-details-modal-body-validation-result-table-title-content" id="App-body-right-tab-job-details-modal-title-validation-year">
			          			<div className="App-body-right-tab-job-details-modal-body-validation-result-table-title-content-detail">
			          				Year
			          			</div>
			          		</div>
			          		<div className="App-body-right-tab-job-details-modal-body-validation-result-table-title-content" id="App-body-right-tab-job-details-modal-title-validation-size">
			          			<div className="App-body-right-tab-job-details-modal-body-validation-result-table-title-content-detail">
			          				Size Validation
			          			</div>
			          		</div>
			          		<div className="App-body-right-tab-job-details-modal-body-validation-result-table-title-content" id="App-body-right-tab-job-details-modal-title-validation-count">
			          			<div className="App-body-right-tab-job-details-modal-body-validation-result-table-title-content-detail">
			          				Count Validation
			          			</div>
			          		</div>
						</div>
						<div className="App-body-right-tab-job-details-modal-body-validation-result-table-body">
							{showSpinner ?
								<Spinner />
							:
								<div className="App-body-right-tab-job-details-modal-body-validation-result-table-body-content" >
						 			<div className="App-body-right-tab-job-details-modal-body-validation-result-table-content" id="App-body-right-tab-job-details-modal-content-validation-year">
	          							<div className="App-body-right-tab-job-details-modal-body-validation-result-table-content-detail">
						 					{validation.year}
						 				</div>
							 		</div>
							 		<div className="App-body-right-tab-job-details-modal-body-validation-result-table-content" id="App-body-right-tab-job-details-modal-content-validation-size">
		          						<div className="App-body-right-tab-job-details-modal-body-validation-result-table-content-detail">
							 				{validation.size_status ? 
							 						<i class="fa fa-check tekion-success-color" aria-hidden="true" onClick={handleSizeValidation}></i>
							 					: 
							 						<i class="fa fa-times tekion-fail-color" aria-hidden="true" onClick={handleSizeValidation}></i>
							 				}
							 			</div>
							 		</div>
							 		<div className="App-body-right-tab-job-details-modal-body-validation-result-table-content" id="App-body-right-tab-job-details-modal-content-validation-count">
		          						<div className="App-body-right-tab-job-details-modal-body-validation-result-table-content-detail">
							 				{validation.count_status ? 
							 						<i class="fa fa-check tekion-success-color" aria-hidden="true" onClick={handleCountValidation}></i>
							 					: 
							 						<i class="fa fa-times tekion-fail-color" aria-hidden="true" onClick={handleCountValidation}></i>
							 				}
							 			</div>
							 		</div>
						 		</div>
						 	}
						</div>
    				</div>
    			</div>
    			{showCountValidation && <CountValidation handleJobClose={handleCountValidationClose} validationData={validation}/>}
    			{showSizeValidation && <SizeValidation handleJobClose={handleSizeValidationClose} validationData={validation} />}
			</div>
		)

}

export default ValidationResult